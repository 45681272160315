import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonHeader,
  IonTitle,
  IonButton,
  IonImg
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import "./Offerings.css";
import images from "../images";
import SubHeader from "../Components/SubHeader";
import DTFooter from "../Components/footer";
import BotCard from "../Components/BotCard";
import useAnalytics from '../Components/useAnalytics';
import "../App.css";
import "./Home.css";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { botStruct } from './Type';
import { allbots } from "../actions/index.js";

import { apiURL } from "../Services/config"
import CustomTree from "../Components/CustomTree";


window.name = ""
const apiEndPoint = apiURL;
const TechAreas: React.FC = () => {
  const botCollection = useSelector<any>(state => state.allbots as unknown as botStruct[]) || '';
  const dispatch = useDispatch();
  const [allbot, setallbots] = useState([])
  var TechBotsfromApi: botStruct[];
  TechBotsfromApi = botCollection as unknown as botStruct[];
  if (TechBotsfromApi.length === 0) {
    // history.push('/Home')
    dispatch(allbots(allbot));
  }
  TechBotsfromApi = TechBotsfromApi.filter(value => value.BotName !== "")
  const [subcatdesc, setsubcatdesc] = useState("");

  console.log(botCollection);
  const subcatdata: any = useSelector<any>(state => state.selectsubcat) || '';
  var subcattemp = subcatdata.split('+');

  var description: string;

  const [, setSubCategories] = useState([])
  const [Data] = useState([])

  const [loaded, setloaded] = useState(false)
  if (loaded) {
  }
  useEffect(() => {
    if (subcatdata === "") {
      axios.get(apiEndPoint + 'api/getSubCategoriesByCategory/Tech Areas').then(res => { setSubCategories(res.data) })
      if (TechBotsfromApi.length === 0) {
        axios.get(apiEndPoint + 'api/getBots').then(res => setallbots(res.data))

      }
    }
    if (subcatdata !== "") {
      axios.get(apiEndPoint + 'api/getProposedContent/Tech Areas/' + subcatdata).then(res => setsubcatdesc(res.data))
    }

    setloaded(true);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcatdata])
  useAnalytics(null);

  const contentRef = useRef(null);
  const scrollToPoint = (elementId: string) => {
    // @ts-ignore
    contentRef.current.scrollToPoint(0, document.getElementById(elementId).offsetTop - 50);
  };



  TechBotsfromApi = subcatdata === "" ? TechBotsfromApi.filter((t: any) => t.Category === "Tech Areas") : TechBotsfromApi.filter((t: any) => t.SubCategory === subcattemp[0]);
  TechBotsfromApi = TechBotsfromApi.filter(filter => filter.BotName !== "")
  var botcount: number = TechBotsfromApi.length;
  var header = subcatdata !== "" ? subcattemp[0] : "TECH AREAS PORTFOLIO"
  description = subcatdata !== "" ? subcatdesc :
    "Automate your team's technical activities with the use of Deloitte pre-built bots. Leveraging these bots can increase team efficiency in the areas of Analytics, Core Modernization, Data Management & Governance, DevOps & Operate, Microservices & Integration, SAP Experience, CIO Services & Tech Architecture,Testing, Security, and more. Click the View Bots button below to browse the SAP Tech Areas bot portfolio.";
  const SubCategoryForFilter: any = useSelector<any>(state => state.GetCatSubCat) || '';

  if (SubCategoryForFilter !== "") {
    var CatSubCat = SubCategoryForFilter.split('/');
    TechBotsfromApi = TechBotsfromApi.filter((t: any) => t.Category === CatSubCat[0] && t.SubCategory === CatSubCat[1]);
    botcount = TechBotsfromApi.length;
  }
  if (loaded)
    return (
      <IonPage>
        <IonHeader>
          {/* <IonToolbar className="page-subheader-techAreas"> */}
          <IonTitle className="page-subheader-techAreas">
            {" "}
            {/* <SubHeader list={subCategories}/> */}
            <SubHeader list={Data} />
          </IonTitle>
          {/* </IonToolbar> */}
        </IonHeader>

        <IonContent scrollEvents={true} ref={contentRef}>
          <div className="content-margin">
            <IonGrid>
              <IonRow>
                <IonCol size-xs="12" size-sm="12" size-lg="6">
                  <div className="main-card"><IonLabel className="offering-Header">
                    {header}
                  </IonLabel></div> <br />
                  <p className="offering-cardcontent">
                    {description}
                  </p>
                  <div>
                    <br />
                    <IonButton
                      size="small"
                      color="primary"
                      className="offering-button button-size"
                      fill="solid"
                      id="viewBot"
                      onClick={() => scrollToPoint("tech-bots")}>
                      VIEW BOTS
                </IonButton>
                  </div>
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-lg="6">
                  <IonImg className="offering-image" src={images[9].src}></IonImg>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonGrid id="tech-bots">
            <IonRow>
              <IonCol>
                <IonLabel className="offering-Header content-margin">
                  BOTS PORTFOLIO
              </IonLabel>
              </IonCol>
            </IonRow>
            <div className="content-gray">
              <div className="  content-margin">
                <IonRow>
                  <IonCol size-xs="12" size-sm="12" size-lg="3">
                    <CustomTree ProcessAreaDisplay={false} TechAreaDisplay={true} PlatformDisplay={true} SDLCPhaseVisible={true}></CustomTree>
                  </IonCol>
                  <IonCol size-xs="6" size-sm="6" size-lg="9">
                    <div className="bots-count">{botcount} results</div>
                    {TechBotsfromApi.map((value, index) => {
                      return (

                        <div>
                          <BotCard
                            key={value._id}
                            id={value._id as unknown as string}
                            description={value.TechnicalDetails}
                            botName={value.BotName}
                            likes={value.Likes}
                            views={value.NoOfPeopleViewed}
                            //rank={value.Rank}
                            // stars={value.StarRating}
                            platforms={value.hasOwnProperty('Platforms') && value.Platforms.length > 0 ? value.Platforms : []}
                            sdlcPhase={value.hasOwnProperty('SDLCPhase') && value.SDLCPhase !== null && value.Platforms.length > 0 ? value.SDLCPhase : []}
                            stars={index.toString()}
                            subCategory={value.SubCategory}
                          ></BotCard>
                        </div>

                      );
                    })}
                  </IonCol>
                </IonRow>
              </div>
            </div>
          </IonGrid>
          <DTFooter />
        </IonContent>
      </IonPage>
    ); else { return (<div>Loading...</div>) }
};

export default TechAreas;
