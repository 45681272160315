import  React  from "react";
import { IonPage, IonHeader, IonContent, IonCol  } from "@ionic/react";
import Data from "../data";
import BotCard from "../Components/BotCard";
import { RouteComponentProps } from "react-router";

interface searchProp extends RouteComponentProps {
    searchString: string
}

let botcount: number = Data.BotDetailsMaster.length;
const SearchCatalog: React.FC<searchProp>=({searchString, history})=>{
    return(
    <IonPage>
        <IonHeader>

        </IonHeader>
        <IonContent>
        <div>{searchString}</div>
            <div className="content-gray content-margin">
            <div className="bots-count">{botcount} results</div>
                {Data.BotDetailsMaster.map(value => {
                  return (
                    <IonCol size-sm="6" size-lg="3">
                      <div>
                        <BotCard
                          id={value.id as unknown as string}
                          description={value.BotDescription}
                          botName={value.BotTitle}
                          likes={value.Likes}
                          views={value.ViewedNumbers}
                         // rank={value.Rank}
                          stars={value.StarRating}
                        ></BotCard>
                      </div>
                    </IonCol>
                  );
                })}
            </div>
        </IonContent>
    </IonPage>
    )
}

export default SearchCatalog;