import React from "react";
import {
  IonCard,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { botStruct } from "../pages/Type";
import './BotInfoCard.css';
import "../App.css";
import "../Assets/group-4@2x.png";

const BotInfoCard: React.FC<botStruct> = ({ BotName, PublishedDate, StarRating, Contributers, NoOfPeopleViewed }: botStruct) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const GetContributer = (contributers: string[]) => {
    let temp: string = "";
    contributers.forEach((e: string) => {
      temp = temp + e + ", ";
      temp = temp.substring(0, temp.length - 1);
    });
    return temp;
  };
  return (
    <IonCard>
      <div className="info-card">
        <IonGrid>
          <IonRow>
            <IonCol align-self-center={true} size="8">
              <div>
                <IonCardTitle className="title">
                  {BotName}
                </IonCardTitle>
                <div>
                  <div className="card-line"><div className="detailing card-label">Published </div>: <div className="detailing card-detail">{PublishedDate}</div></div>
                  <div className="card-line"><div className="detailing card-label">Contributors </div>: <div className="detailing card-detail">{Contributers}</div></div>
                </div>
                {/*   <div className="rating"><div className="detailing card-label"><IonImg src="../Assets/group-4@2x.png"></IonImg></div><div className="detailing  card-detail"><div className="views">{ViewedNumbers}</div><div><BotRating StarRating ={StarRating}></BotRating></div></div></div>
                      */}  </div>
            </IonCol>
            <IonCol size="4">
              <IonImg className="image-background" src="static/media/CardHeader.6c57bbfc.png"></IonImg>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonCard>
  );
}
export default BotInfoCard;