import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonGrid,
  IonRow,
  IonIcon,
  IonCol,
  IonLabel,
  IonInput,
  IonTextarea,
  IonModal,
  IonButton,
} from "@ionic/react";
import { arrowRoundBack } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
import "./SubmitIdeaScreen.css";
import BotInfoCard from "../Components/BotInfoCard";
import "./Inquiry.css";
import { botStruct } from "./Type";
import UserTag from "../Components/UserTag";
import useAnalytics from '../Components/useAnalytics';
import axios from 'axios'
import { apiURL } from "../Services/config"

const apiEndPoint = apiURL;
interface BotDetail
  extends RouteComponentProps<{
    id: string;
  }> { }

const Inquiry = ({ match, history }: BotDetail) => {
  const [teamInput, setteamInput] = useState(false);
  const [messageInput, setmessageInput] = useState(false);
  const [, setmessageLength] = useState(0);
  const [, setInputLength] = useState(0);
  const [loaded, setLoaded] = useState(false);


  const teamInputChange = (e: CustomEvent) => {
    if (e.detail.value.length > 1) {
      setteamInput(true);
    } else { setteamInput(false) }
    setInputLength(e.detail.value.length)
  }
  const messageInputChange = (e: CustomEvent) => {
    if (e.detail.value.length > 1) {
      setmessageInput(true);
    } else { setmessageInput(false) }
    setmessageLength(e.detail.value.length)
  }

  const [showModal, setShowModal] = useState(false);
  const [botjson, setbotjson] = useState('');
  const InquirybotId = match.params.id;
  useEffect(() => {
    axios.get(apiEndPoint + 'api/getBot/' + InquirybotId).then(res => setbotjson(res.data))
    setLoaded(true)
  }, [InquirybotId])


  let Botdetailtemp: botStruct = botjson as unknown as botStruct;
  let Contributors: string[];
  Botdetailtemp.Contributers === undefined ? Contributors = [""] : Contributors = Botdetailtemp.Contributers.split(",");
  useAnalytics(Botdetailtemp.BotName);


  if (loaded) {
    return (
      <IonPage>
        <IonHeader>
        </IonHeader>
        <IonContent>
          <div className="content-margin  submit-header-Inquiry header-font">
            {" "}
            <IonButton fill="clear" className="header-font" onClick={() => history.goBack()}><IonIcon
              icon={arrowRoundBack}
              className="back-icon inquiry-back"
            ></IonIcon>{" "}
              <p className="inner-header">SEND A MESSAGE TO {Botdetailtemp.Contributers}</p>
            </IonButton>
          </div>
          <IonGrid>
            <div className="full-width">
              <div className="input-container body-margin label-font">
                <IonRow>
                  <IonCol size-xs="12" size-sm="12" size-lg="12">
                    <IonRow className="inputRow">
                      <div className="full-width label-font">
                        <IonLabel className="label-font">PROJECT/TEAM</IonLabel>
                      </div>
                      <div className="full-width">
                        <IonInput
                          className="inputbox"
                          placeholder="Project/team name"
                          maxlength={100}

                          onIonChange={(e) => teamInputChange(e)}
                        ></IonInput>
                        {/*  <div className="charachter-limit">{100-InputLength}/100</div> */}
                      </div>
                    </IonRow>
                    <IonRow>
                      <div className="full-width label-font">
                        <IonLabel>MESSAGE</IonLabel>
                      </div>
                      <div className="full-width ">
                        <IonTextarea
                          className="inputbox"
                          rows={10}
                          //value={message}
                          placeholder="Your brief message stating your requirements/purpose"
                          maxlength={1500}

                          onIonChange={(e) => messageInputChange(e)}
                        ></IonTextarea>
                        {/* <div className="charachter-limit">{1500-messageLength}/1500</div>  */}
                      </div>
                    </IonRow>
                    <IonModal cssClass="Inquiry submitIdea-model" isOpen={showModal} onDidDismiss={() => {
                      history.goBack();
                    }}>
                      <div className="Inquiry-ModalContainer">
                        {<p className="modal-bigtext">THANK YOU FOR<br />YOUR MESSAGE</p>}
                        <p className="modal-message">
                          The Bot Inquiry website functionality is currently unavailable. Please email the Bot Contributor(s) and SAP Bot Council directly with your inquiry request:<br />
                          <p className="email-highlighter">kamurali@deloitte.com<br />
sharshberger@deloitte.com<br />
thoshing@deloitte.com</p></p>
                        <div className="ModalButtonContainer">
                          <IonButton
                            id="return-home-from-enquiry"
                            fill="solid"
                            className="button-text"
                            onClick={() => {
                              setShowModal(false);
                              history.goBack();
                            }}

                          >
                            RETURN TO BOT
                      </IonButton>
                        </div>
                      </div>
                    </IonModal>
                    <div className="button-container">
                      <IonButton
                        id={"BotDetail-sendMessage-" + InquirybotId}
                        fill="solid"
                        onClick={() => setShowModal(true)}
                        className="bot-detail-message button-text"
                        disabled={!(messageInput && teamInput)}
                      >
                        Send message
                  </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </div>
            <div className="content-margin">
              <IonRow>
                <IonCol size-xs="12" size-sm="12" size-lg="7">
                  {loaded ? <BotInfoCard  {...Botdetailtemp}></BotInfoCard> : <div></div>}
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-lg="5">
                  {loaded ? <UserTag username={Contributors[0]}
                    publishedbotcount={""}
                    description={""}
                  >
                  </UserTag> : <div></div>}
                </IonCol>
              </IonRow>
            </div>

          </IonGrid>
          <DTFooter />
        </IonContent>
      </IonPage>

    );
  } else return (<div>Loading...</div>)
};
export default Inquiry;
