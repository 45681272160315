import React from 'react';
import './Customslider.css';
import images from "../images";
import { IonButton, IonLabel } from "@ionic/react";
import Carousel from 'react-bootstrap/Carousel';

const Customslider: React.FC = CustomSlider => {
  const scrollToPoint = (elementId: string) => {
    // @ts-ignore
    contentRef.current.scrollToPoint(0, document.getElementById(elementId).offsetTop - 50);
  };

  return (
    <div>
      <Carousel controls={true} pause='hover' interval={50000}>
        <Carousel.Item>
          <div className="flex-container">
            <div className="flex-child FirstChild">
              <div>
                <div className="mainHeading leftalign">
                  ERP POWERED BY AI
                      <div className="popBtos-hrzLine meetline"></div>
                </div>

                <div className="twinBot txtAlign-left leftalign">BOT STORE</div>
              </div>
              <div className="oneStop secondary-content-margin txtAlign-left leftalign">
                A one stop-shop to browse and inquire about Deloitte created
                SAP bots. Click below to get started
                  </div>
              <div>
                <IonButton
                  size="small"
                  className="button-size button-text btnAlign-left leftalign"
                  color="primary"
                  fill="solid"
                  id="explorebutton"
                  href="/ExploreBots"
                  onClick={() => scrollToPoint("firstrow")}
                >
                  EXPLORE BOTS
                  </IonButton>
                <IonButton
                  size="small"
                  className="button-size button-text home-submit btnAlign-left"
                  color="primary"
                  fill="outline"
                  routerLink="SubmitIdeaScreen"
                  href="/SubmitIdeaScreen"
                  id="submitIdeaScreen"
                >
                  SUBMIT IDEA
                  </IonButton></div>
            </div>
            <div className="flex-child green">
              <img
                className="d-block   imageheightFirstSlide"

                src={images[8].src}
                alt="First slide"
              />
            </div>

          </div>
        </Carousel.Item>
        {/* 1st slide */}

        {/* 2nd slide */}
        <Carousel.Item>
          <div className="flex-container">
            <div className="flex-child ">
              <img
                className="d-block   imageheight"

                src={images[9].src}
                alt="First slide"
              />
            </div>


            <div className="flex-child FirstChild">
              <div className="main-card divAlign-top">

                <IonLabel className="offering-Header rightalign">
                  TECH AREAS
               </IonLabel>

              </div>
              <br />
              <p className="offering-cardcontent rightalign">
                Automate your team's technical activities with the use of Deloitte pre-built bots. Leveraging these bots can increase team efficiency in the areas of Analytics, Core Modernization, Data Management & Governance, DevOps & Operate, Microservices & Integration, SAP Experience, CIO Services & Tech Architecture, Testing, Security, and more. Click the View Bots button below to browse the SAP Tech Areas bot portfolio.
             <br /></p>
              <IonButton
                size="small"
                color="primary"
                className="offering-button button-size button-text btnAlign-left"
                fill="solid"
                routerLink="ExploreBots"
                href="/ExploreBots"
                id="techAreas"
                onClick={() => { localStorage.setItem("HomeSliderNav", "TechAreas"); }}
              >
                VIEW BOTS
             </IonButton>
            </div>
          </div>
        </Carousel.Item>
        {/* 3rd slide */}
        <Carousel.Item>
          <div className="flex-container">

            <div className="flex-child FirstChild">
              <div className="main-card divAlign-top">

                <IonLabel className="offering-Header">
                  PROCESS AREAS
               </IonLabel>

              </div>
              <br />
              <p className="offering-cardcontent txtAlign-left">
                Functional teams can utilize Deloitte pre-built bots to automate their business processes. Leveraging these bots can substantially increase process efficiencies in the areas of Finance, Human Resources, Marketing, Operations & Supply Chain, Procurement, Sales, PMO and more. Click the View Bots button below to browse the SAP Process Areas bot portfolio.
             </p>
              <br />
              <IonButton
                size="small"
                color="primary"
                className="offering-button button-size button-text btnAlign-left"
                fill="solid"
                routerLink="ExploreBots"
                href="/ExploreBots"
                id="processAreas"
                onClick={() => { localStorage.setItem("HomeSliderNav", "ProcessAreas"); }}
              >
                VIEW BOTS
             </IonButton>
            </div>
            <div className="flex-child green">
              <img
                className="d-block   imageheight"

                src={images[10].src}
                alt="First slide"
              />
            </div>
          </div>
        </Carousel.Item>
        {/* 4th slide */}
        <Carousel.Item>
          <div className="flex-container">

            <div className="flex-child">
              <img
                className="d-block  imageheight"

                src={images[11].src}
                alt="First slide"
              />
            </div>
            <div className="flex-child FirstChild">
              <div className="main-card divAlign-top">

                <IonLabel className="offering-Header rightalign">
                  JOIN THE COMMUNITY<div className="popBtos-hrzLine meethrzline"></div>
                </IonLabel>
                <br /><br />
                <IonLabel className="twinHomeBot rightalign">
                  Become a contributor
               </IonLabel>
                <br />
              </div>
              <br />
              <p className="offering-cardcontent rightalign">
                Are you interested in becoming more involved with Deloitte's SAP Digital Twin community?
<br />
              Join the team and contribute to the firm's cutting edge RPA innovation
              by sharing your bot ideas through the Submit ideas button below.
             </p>
              <br />
              <IonButton
                size="small"
                color="primary"
                className="offering-button button-size button-text rightalign"
                fill="solid"
                routerLink="SubmitIdeaScreen"
                href="/SubmitIdeaScreen"
                id="SubmitIdeaScreen"
              >
                SUBMIT IDEA
             </IonButton>
            </div>
          </div>
        </Carousel.Item>
        {/* 5th slide */}
        <Carousel.Item>
          <div className="flex-container">

            <div className="flex-child FirstChild">
              <div className="main-card divAlign-top">

                <IonLabel className="offering-Header">
                  OTHER OPTIONS<div className="popBtos-hrzLine meethrzline"></div>
                </IonLabel>
                <br />
                <IonLabel className="offering-Header twinHomeBot">
                  Looking for
               </IonLabel>
                <br />
                <IonLabel className="offering-Header twinHomeBot">
                  something else?
               </IonLabel>
                <br />
              </div>
              <br />
              <p className="offering-cardcontent txtAlign-left">
                The SAP Digital Twin community focuses on driving RPA innovation specifically within the firm's SAP offering space. For other RPA inquiries, you can start by visiting Deloitte's Robotic & Intelligent Automation Knowledge Exchange site.
             </p>
              <IonButton
                size="small"
                color="primary"
                className="offering-button button-size button-text btnAlign-left"
                fill="solid"
                //routerLink="Click"
                href="https://kx.deloitteresources.com/hot_topics/Pages/Robotics_and_Cognitive.aspx"
                target="_blank"
                id="clickHere"
              >
                CLICK HERE
             </IonButton>
            </div>
            <div className="flex-child">
              <img
                className="d-block   imageheight"

                src={images[12].src}
                alt="First slide"
              />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );


}

export default Customslider;