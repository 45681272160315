import { useEffect } from 'react'
import { useHistory } from 'react-router'
var windowObject = window as any;
export const useAnalytics = (input: any) => {
  const history = useHistory();
  var currentPage = "";
  switch (history.location.pathname !== "") {
    case history.location.pathname.indexOf('BotDetail') !== -1:
      currentPage = "bot-detail | " + input;
      break;
    case history.location.pathname.indexOf('Inquiry') !== -1:
      currentPage = "bot-detail | " + input + " | Inquiry";
      break;
    default:
      currentPage = history.location.pathname.substring(1)
      break;
  }
  useEffect(() => {
    windowObject.analyticsDataLayer = {
      "pageInfo": {
        "pageName": currentPage,
        "userID": ""
      }
    };
    if (windowObject._satellite && windowObject._satellite.track) {
      windowObject._satellite.track("pageView");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
export default useAnalytics