import React from 'react';
import { useState } from 'react'
import './CustomTree.css';
import { IonIcon, IonButton } from '@ionic/react';
import { addCircleOutline, removeCircleOutline } from "ionicons/icons";
import axios from 'axios';
import { GetCatSubCat, GetTypeOfCategory } from '../actions/index.js';
import { useDispatch } from 'react-redux';
import { apiURL } from '../Services/config';

interface CategoryDisplay {
    ProcessAreaDisplay: boolean,
    TechAreaDisplay: boolean,
    PlatformDisplay: boolean,
    SDLCPhaseVisible: boolean
}
const CustomTree: React.FC<CategoryDisplay> = CategoryDisplay => {
    const [subCatselected, setsubCatselected] = useState("");
    const [PA, setPA] = useState([]);
    const [TA, setTA] = useState([]);
    const [PLAT, setPLAT] = useState([]);
    const [SDLC, setSDLCCount] = useState([]);

    const [PAGps, setPAGps] = useState([]);
    const [TAGps, setTAGps] = useState([]);
    const [PLATGps, setPLATGps] = useState([]);
    const [SDLCGps, setSDLCCountGps] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [type, getSelectedType] = useState('Commercial');
    var ProcessArea: any;
    var TechArea: any;
    var Platforms: any;
    var SDLCPhase: any;

    var ProcessAreaGps: any;
    var TechAreaGps: any;
    var PlatformsGps: any;
    var SDLCPhaseGps: any;

    if (PA.length === 0) {
        axios.get(apiURL + `/api/getSubCategoriesCountByCategory/Process%20Areas/Commercial`).then(res => setPA(res.data))
        // useEffect(()=>{
        //     axios.get('https://digitaltwin-dev-api.azurewebsites.net/api/getBots').then(res => setallbots(res.data))
        // },[])
    }
    if (TA.length === 0) {
        axios.get(apiURL + `/api/getSubCategoriesCountByCategory/Tech%20Areas/Commercial`).then(res => setTA(res.data))
        // useEffect(()=>{
        //     axios.get('https://digitaltwin-dev-api.azurewebsites.net/api/getSubCategoriesCountByCategory/Tech%20Areas').then(res=> setTA(res.data))
        //   },[])
    }
    if (PLAT.length === 0) {
        axios.get(apiURL + `/api/getPlatformsCount/Commercial`).then(res => setPLAT(res.data))
        //   useEffect(()=>{
        //     axios.get('http://digitaltwin-dev-api.azurewebsites.net/api/getPlatformsCount').then(res=> setPLAT(res.data))
        //   },[])
    }
    if (SDLC.length === 0) {
        axios.get(apiURL + `/api/getSDLCPhaseCount/Commercial`).then(response => setSDLCCount(response.data));
    }


    if (PAGps.length === 0) {
        axios.get(apiURL + `/api/getSubCategoriesCountByCategory/Process%20Areas/GPS`).then(res => setPAGps(res.data))
        // useEffect(()=>{
        //     axios.get('https://digitaltwin-dev-api.azurewebsites.net/api/getBots').then(res => setallbots(res.data))
        // },[])
    }
    if (TAGps.length === 0) {
        axios.get(apiURL + `/api/getSubCategoriesCountByCategory/Tech%20Areas/GPS`).then(res => setTAGps(res.data))
        // useEffect(()=>{
        //     axios.get('https://digitaltwin-dev-api.azurewebsites.net/api/getSubCategoriesCountByCategory/Tech%20Areas').then(res=> setTA(res.data))
        //   },[])
    }
    if (PLATGps.length === 0) {
        axios.get(apiURL + `/api/getPlatformsCount/GPS`).then(res => setPLATGps(res.data))
        //   useEffect(()=>{
        //     axios.get('http://digitaltwin-dev-api.azurewebsites.net/api/getPlatformsCount').then(res=> setPLAT(res.data))
        //   },[])
    }
    if (SDLCGps.length === 0) {
        axios.get(apiURL + `/api/getSDLCPhaseCount/GPS`).then(response => setSDLCCountGps(response.data));
    }

    ProcessArea = Object.values(PA);
    TechArea = Object.values(TA);
    Platforms = Object.values(PLAT);
    SDLCPhase = Object.values(SDLC);

    ProcessAreaGps = Object.values(PAGps);
    TechAreaGps = Object.values(TAGps);
    PlatformsGps = Object.values(PLATGps);
    SDLCPhaseGps = Object.values(SDLCGps);

    const dispatch = useDispatch();

    const updateBot = (name: string, type: any) => {
        window.name = name;
        dispatch(GetCatSubCat(name));
        dispatch(GetTypeOfCategory(type));
        setSubcat(selectedSubCat ? false : true);
        localStorage.setItem("searchflag", "0");
        setsubCatselected(name.split('/')[1]);
    }
    var PADivDisplay = CategoryDisplay.ProcessAreaDisplay ? "" : "CatDivDisplay";
    var TADivDisplay = CategoryDisplay.TechAreaDisplay ? "" : "CatDivDisplay";
    var PlatformDivDisplay = CategoryDisplay.PlatformDisplay ? "" : "CatDivDisplay";
    var SDLCPhaseDisplay = CategoryDisplay.SDLCPhaseVisible ? '' : 'CatDivDisplay';
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var is_accordion_expanded =  isAccordionExpanded ? "show_up" : "hide";
    const [showAnswer, setshowAnswer] = useState(false);
    var second_div_class = showAnswer ? "show_up" : "hide";
    const [showAnswer1, setshowAnswer1] = useState(false);
    var second_div_class1 = showAnswer1 ? "show_up" : "hide";
    const [showAnswer2, setshowAnswer2] = useState(false);
    var second_div_class2 = showAnswer2 ? "show_up" : "hide";
    const [showAnswer3, setshowAnswer3] = useState(false);
    var second_div_class3 = showAnswer3 ? "show_up" : "hide";
    const [selectedSubCat, setSubcat] = useState(false);
    var subCatselectedHighlight = subCatselected ? "HighlightDiv" : "";
    var DivclassName = subCatselectedHighlight + " " + second_div_class;

    const [isAccordionExpanded1, setIsAccordionExpanded1] = useState(false);    
    const [showAnswer4, setshowAnswer4] = useState(false);
    var second_div_class4 = showAnswer4 ? "show_up" : "hide";
    const [showAnswer5, setshowAnswer5] = useState(false);
    var second_div_class5 = showAnswer5 ? "show_up" : "hide";
    const [showAnswer6, setshowAnswer6] = useState(false);
    var second_div_class6 = showAnswer6 ? "show_up" : "hide";
    const [showAnswer7, setshowAnswer7] = useState(false);
    var second_div_class7 = showAnswer7 ? "show_up" : "hide";
    return (
        <div>
            <div id="accord_container">
                <div className="Filter-height">  </div>
                <div>
                    <div id="Treeheader" onClick={() => setIsAccordionExpanded(isAccordionExpanded ? false : true)}>
                        <IonIcon className="arrow arrow-color" icon={isAccordionExpanded ? removeCircleOutline : addCircleOutline}></IonIcon>
                        <p className="CustomTreequestion">Commercial</p>
                    </div>
                    {
                        isAccordionExpanded ?
                            <div className='submenu'>
                                <div className={PADivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer(showAnswer ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Process Areas</p>
                                    </div>
                                    {
                                        ProcessArea.map((name: any) => (

                                            <div id="Treebody" onClick={() => updateBot("Process Areas/" + name._id, 'Commercial')} className={DivclassName}>
                                                <div className="SubCatCountLeft">{name._id}</div><div className="SubCatCountRight">{name.count}</div>

                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={TADivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer1(showAnswer1 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer1 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Tech Areas</p>
                                    </div>
                                    {
                                        TechArea.map((name: any) => (
                                            <div id="Treebody" onClick={() => updateBot("Tech Areas/" + name._id,  'Commercial')} className={second_div_class1}>
                                                <div className="SubCatCountLeft">
                                                    {/* <IonLabel id={name._id} className={setBgSubCat}>{name._id}</IonLabel> */}
                                                    {name._id}
                                                </div><div className="SubCatCountRight">{name.count}</div>

                                            </div>))
                                    }
                                </div>
                                <div className={PlatformDivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer2(showAnswer2 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer2 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Platform</p>
                                    </div>
                                    {
                                        Platforms.map((name: any) => (
                                            <div id="Treebody" onClick={() => updateBot("Platforms/" + name._id,  'Commercial')} className={second_div_class2}>
                                                <div className="SubCatCountLeft">{name._id}</div><div className="SubCatCountRight">{name.count}</div>
                                            </div>))
                                    }
                                </div>
                                <div className={SDLCPhaseDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer3(showAnswer3 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer3 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">SDLC Phase</p>
                                    </div>
                                    {
                                        SDLCPhase.map((data: any) => (
                                            <div id="Treebody" onClick={() => updateBot('getBotsBySDLCPhase/' + data._id,  'Commercial')} className={second_div_class3}>
                                                <div className="SubCatCountLeft">{data._id}</div>
                                                <div className="SubCatCountRight">{data.count}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                             : ''
                    }
                </div>
                
                <div>
                    <div id="Treeheader" onClick={() => setIsAccordionExpanded1(isAccordionExpanded1 ? false : true)}>
                        <IonIcon className="arrow arrow-color" icon={isAccordionExpanded1 ? removeCircleOutline : addCircleOutline}></IonIcon>
                        <p className="CustomTreequestion">GPS</p>
                    </div>
                    {
                        isAccordionExpanded1 ?
                            <div className='submenu'>
                                <div className={PADivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer4(showAnswer4 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer4 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Process Areas</p>
                                    </div>
                                    {
                                        ProcessAreaGps.map((name: any) => (

                                            <div id="Treebody" onClick={() => updateBot("Process Areas/" + name._id, 'GPS')} className={second_div_class4}>
                                                <div className="SubCatCountLeft">{name._id}</div><div className="SubCatCountRight">{name.count}</div>

                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={TADivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer5(showAnswer5 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer5 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Tech Areas</p>
                                    </div>
                                    {
                                        TechAreaGps.map((name: any) => (
                                            <div id="Treebody" onClick={() => updateBot("Tech Areas/" + name._id,  'GPS')} className={second_div_class5}>
                                                <div className="SubCatCountLeft">
                                                    {/* <IonLabel id={name._id} className={setBgSubCat}>{name._id}</IonLabel> */}
                                                    {name._id}
                                                </div><div className="SubCatCountRight">{name.count}</div>

                                            </div>))
                                    }
                                </div>
                                <div className={PlatformDivDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer6(showAnswer6 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer6 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">Platform</p>
                                    </div>
                                    {
                                        PlatformsGps.map((name: any) => (
                                            <div id="Treebody" onClick={() => updateBot("Platforms/" + name._id,  'GPS')} className={second_div_class6}>
                                                <div className="SubCatCountLeft">{name._id}</div><div className="SubCatCountRight">{name.count}</div>
                                            </div>))
                                    }
                                </div>
                                <div className={SDLCPhaseDisplay}>
                                    <div id="Treeheader" onClick={() => setshowAnswer7(showAnswer7 ? false : true)}>
                                        <IonIcon className="arrow arrow-color" icon={showAnswer7 ? removeCircleOutline : addCircleOutline}></IonIcon>
                                        <p className="CustomTreequestion">SDLC Phase</p>
                                    </div>
                                    {
                                        SDLCPhaseGps.map((data: any) => (
                                            <div id="Treebody" onClick={() => updateBot('getBotsBySDLCPhase/' + data._id,  'GPS')} className={second_div_class7}>
                                                <div className="SubCatCountLeft">{data._id}</div>
                                                <div className="SubCatCountRight">{data.count}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                             : ''
                    }
                </div>
                <div>
                </div>
            </div>
            <IonButton
                size="small"
                className="button-size button-text btnAlign-left leftalign"
                color="primary"
                fill="solid"
                id="explorebutton"
                // href="/ExploreBots"
                onClick={() => updateBot("clearFilter", '')}
            >
                CLEAR FILTERS
        </IonButton>
        </div>
    );
}

export default CustomTree;
