import React from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { IonApp } from "@ionic/react";

import Offerings from "./pages/Offerings";
import Home from "./pages/Home";
import TechAreas from "./pages/TechAreas";
import ProcessAreas from "./pages/ProcessAreas";
import DTHeader from "./Components/DTHeader";
import BotDetail from "./pages/BotDetail";
import Inquiry from "./pages/Inquiry"
import ExploreBots from "./pages/ExploreBots";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/*custom class */
import "./App.css";

/* Theme variables */
import "./theme/variables.css";
import SubmitIdea from "./pages/SubmitIdeaScreen";
import SearchCatalog from "./pages/SerachCatalog";
import SearchScreen from "./pages/SearchScreen";
import SubCategoryPages from "./pages/SubCategoryPages";
import FAQ from "./pages/FAQ"
import Contact from "./pages/ContactUs"
import Admin from './Components/Admin'
import ViewBot from './Components/ViewBot';
import CreateBot from './Components/CreateBot'
import MyAccount from './Components/MyAccount'
import MyBots from "./Components/MyBots";
const App: React.FC = () => (
  <IonApp>
    <BrowserRouter>
      <DTHeader />
      <Switch>
        <Route path="/Home" component={Home} />
        <Route path="/Offerings" component={Offerings} />
        <Route path="/TechAreas" component={TechAreas} />
        <Route path="/ProcessAreas" component={ProcessAreas} />
        <Route path="/SubmitIdeaScreen" component={SubmitIdea} />
        <Route path="/SearchScreen" component={SearchScreen} />
        <Route path="/BotDetail/:id" component={BotDetail} />
        <Route path="/Inquiry/:id" component={Inquiry} />
        <Route path="/SubCategoryPages/:id" component={SubCategoryPages} />
        <Route path="/SearchCatalog" component={SearchCatalog} />
        <Route path="/FAQ" component={FAQ} />
        <Route path="/ContactUs" component={Contact} />
        <Route path="/Admin" component={Admin} />
        <Route path="/createBot" exact component={CreateBot} />
        <Route path="/viewBot/:id" exact component={ViewBot} />
        <Route path="/myAccount" exact component={MyAccount} />
        <Route path="/myBots" exact component={MyBots} />
        <Route path="/ExploreBots" component={ExploreBots} />
        <Route path="/" render={() => <Redirect to="/Home" />} exact={true} />
      </Switch>
    </BrowserRouter>
  </IonApp>
);

export default App;
