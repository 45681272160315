import React, { useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonTitle,
  IonRouterLink,
  IonButtons,
  IonButton,
  IonProgressBar,
  IonPopover,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import "../App.css";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { selectsub } from '../actions/index.js';
import Axios from "axios";
import User from '../Components/ValidateAdmin.js';
import { Link } from "react-router-dom";
import { apiURL } from "../Services/config"
var subCategoryImage = require('../Assets/Profile.png') as string
const apiEndPoint = apiURL;
const DTHeader = ({ history }: RouteComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [category, setcategory] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [selectedButtonId, setselectedButtonId] = useState("0");
  const [showPopover, setShowPopover] = useState(false);
  const dispatch = useDispatch();
  // console.log(history.length);
  useEffect(() => {
    Axios.get(apiEndPoint + 'api/getCategories').then(res => setcategory(res.data))
    setloaded(true)
  }, [])


  if (loaded) {

    return (
      <IonHeader translucent className="d-print-none">
        <IonProgressBar color="primary" value={0.5}></IonProgressBar>
        <IonToolbar className="dtheader main-header header-shadow-sm">
          <IonMenuButton
            slot="start"
            autoHide={false}
            className="ion-mobile-visible"
          />
          <IonTitle slot="start" className="ion-toolbar">
            <IonRouterLink
              onClick={() => {
                setselectedButtonId("0");
                history.push("/Home");
              }}
            >
              <img alt="Deloitte." className="LogoAlign" src={require("../Assets/deloitteLogo.png")} />
            </IonRouterLink>
          </IonTitle>
          <IonButtons slot="start" className="ion-toolbar aligntoLogo">
            <div className="aligncenter">
              {/* {CategoryList(Categories)} */}
              <IonButton id={`dt-ExploreBots`}
                key={55}
                className={`DTheader-tab ${"55" === selectedButtonId ? "button-selected" : ""
                  }`}
                onClick={() => {
                  setselectedButtonId("55");
                  dispatch(selectsub(""));
                  history.push(`/ExploreBots`, { from: window.location.pathname });
                }}
              >
                Explore Bots
        </IonButton>
              <IonButton id={`dt-Submit Idea`}
                key={44}
                className={`DTheader-tab ${"44" === selectedButtonId ? "button-selected" : ""
                  }`}
                onClick={() => {
                  setselectedButtonId("44");
                  dispatch(selectsub(""));
                  history.push(`/SubmitIdeaScreen`);
                }}
              >
                Submit Idea
        </IonButton>
            </div>
          </IonButtons>
          {/* <IonSearchbar
          class="search-bar"
          placeholder="Search by name, type etc"
          slot="secondary"
          id="searchbar"
          value={window.name}
          onIonChange={(e)=>{
             searchChange(e);
          }}
        ></IonSearchbar> */}

          <IonPopover
            isOpen={showPopover}
            onDidDismiss={e => setShowPopover(false)}>
            <IonItem>
              <IonLabel>
                <Link to='/myAccount' onClick={() => setShowPopover(false)}>My Account</Link>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <Link to='/myBots' onClick={() => setShowPopover(false)}>My Bots</Link>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <Link to="/myAccount" onClick={() => setShowPopover(false)}>Logout</Link>
              </IonLabel>
            </IonItem>
          </IonPopover>
          <IonLabel slot="end" id={`dt-Submit Idea`}
            key={33}
            className={`DTheader-tab ${"33" === selectedButtonId ? "button-selected profilecenter" : "profilecenter"
              }`}
          >
            <span>Welcome {User.given_name}<img src={subCategoryImage} className="user-icon" alt=""></img></span>
            {/* <img src={subCategoryImage} onClick={() => setShowPopover(true)} className="user-icon"></img>   */}
          </IonLabel>

          {/* <IonIcon slot="end" onClick={() => setShowPopover(true)} className="user-icon" src={subCategoryImage}></IonIcon> */}
          <IonButton className="LogoAlign" hidden={!User.isAdmin} onClick={() => { history.push('/Admin') }}>Bot Management</IonButton>

        </IonToolbar>

      </IonHeader>
    );
  }
  else return (<div>Loading...</div>);
};

export default withRouter(DTHeader);
