import React, { useState, useEffect, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonCol,
  IonButton,
  IonLabel,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonChip,
} from "@ionic/react";
import { arrowRoundBack } from "ionicons/icons";

import { RouteComponentProps } from "react-router";
import DTFooter from "../Components/footer";
import BotCard from "../Components/BotCard";
import useAnalytics from "../Components/useAnalytics";
import axios from "axios";
import { botStruct } from "./Type";
import { useSelector, useDispatch } from "react-redux";
import { allbots } from "../actions";
import { apiURL } from "../Services/config";

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

const apiEndPoint = apiURL;
interface Bot {
  id: string;
  BotTitle: string;
  BotDescription: string;
  Contributers: string[];
  Tags: string[];
  StarRating: string;
  PublishedOn: string;
  ViewedNumbers: string;
  Platforms: string[];
  SDLCPhase: string[];
}

interface props
  extends RouteComponentProps<{
    id: string;
  }> { }
const BotDetail: React.FC<props> = ({ match, history }) => {
  const botCollection =
    useSelector<any>((state) => (state.allbots as unknown) as botStruct[]) ||
    "";
  const botId = match.params.id;
  const dispatch = useDispatch();
  const [botJson, setbotJson] = useState("");
  const [allbot, setallbot] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [carouselAttachments, setCarouselAttachments] = useState([]);
  let allBots: botStruct[];
  let BotDetail: botStruct;
  let uiPath = "" as string
  let automationAnywhere = "" as string
  let bluePrism = "" as string
  let iRPA = '' as string
  uiPath = require('../Assets/ui_Symbol_PREF_rgb_Orange_digital_129x110.png');
  automationAnywhere = require('../Assets/automation-anywhere-logo-a-only.png');
  bluePrism = require('../Assets/triangle-updated.png');
  iRPA = require('../Assets/iRPA.jpg');
  const [displayUiPath, setDisplayUiPath] = useState(false);
  const [displayAutomationAnywhere, setDisplayAutomationAnywhere] = useState(false);
  const [displayBluePrism, setDisplayBluePrism] = useState(false);
  const [displayiRPA, setDisplayiRPA] = useState(false);

  let downloadButtonPath = require('../Assets/download-button.png') as string

  allBots = (botCollection as unknown) as botStruct[];
  BotDetail = (botJson as unknown) as botStruct;
  if (allBots.length === 0) {
    dispatch(allbots(allbot));
  }

  const [show, setShow] = useState(false);
  let [modalImage, setModalImage] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .get(apiEndPoint + "api/getBot/" + botId)
      .then((res) => {
        let response = res.data;
        // For Carousel
        const imageArray = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF'];
        const videoArray = ['.mp4', '.MP4', '.ogg', '.OGG'];
        const carouselResponse: any = [];
        if (response.Attachments && response.Attachments.length) {
          response.Attachments.map((element: string | string[]) => {

            imageArray.map(fileFormat => {
              if (element.includes(fileFormat)) {
                const carouselItem = {
                  src: element,
                  type: 'image'
                }
                return carouselResponse.push(carouselItem)
              }
              return null;
            });

            videoArray.map(fileFormat => {
              if (element.includes(fileFormat)) {
                const carouselItem = {
                  src: element,
                  type: 'video'
                }
                return carouselResponse.push(carouselItem)
              }
              return null;
            });
            return null;
          });
        }
        if (carouselResponse.length > 0) {
          setCarouselAttachments(carouselResponse);
        } else {
          const staticImages: any = [{
            src: 'https://images.pexels.com/photos/669622/pexels-photo-669622.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            type: 'image'
          },
          {
            src: 'https://images.pexels.com/photos/2058147/pexels-photo-2058147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            type: 'image'
          },
          {
            src: 'https://images.pexels.com/photos/669616/pexels-photo-669616.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            type: 'image'
          },
          {
            src: 'https://images.pexels.com/photos/590044/pexels-photo-590044.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            type: 'image'
          },
          {
            src: 'https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            type: 'image'
          }
          ]
          setCarouselAttachments(staticImages)
        }
        setbotJson(response);

        setDisplayUiPath(false);
        setDisplayAutomationAnywhere(false);
        setDisplayBluePrism(false);
        setDisplayiRPA(false);
        if (response.Platforms !== undefined) {
          response.Platforms.forEach((element: any) => {
            if (element === "UIPath") {
              setDisplayUiPath(true);
            }
            if (element === "Automation Anywhere") {
              setDisplayAutomationAnywhere(true);
            }
            if (element === "Blue Prism") {
              setDisplayBluePrism(true);
            } else if (element === 'iRPA') {
              setDisplayiRPA(true);
            }
          });
        }
      });
    if (allBots.length === 0) {
      axios.get(apiEndPoint + "api/getBots").then((res) => setallbot(res.data));
    }
    setloaded(true);
  }, [allBots.length, botId]);

  let tagList: string[];
  BotDetail.Tags === undefined
    ? (tagList = [""])
    : (tagList = BotDetail.Tags.split(";"));

  let similarBots: botStruct[] = [];
  allBots.map((t) =>
    tagList.map((value) => (t.Tags.includes(value) ? similarBots.push(t) : {}))
  );

  similarBots = similarBots.filter((x) => x._id !== botId);

  useAnalytics(BotDetail.BotName);

  let [showImageContainerState, setState] = useState(true);
  let videoThumbnail: string | any, arrowBackSvg, arrowForwardSvg = "" as string;
  videoThumbnail = require("../Assets/video-thumbnail.png");
  arrowBackSvg = require("../Assets/chevron-back-outline.svg");
  arrowForwardSvg = require("../Assets/chevron-forward-outline.svg");


  const primaryImageRef: any = useRef(null);
  const primaryVideoRef: any = useRef(null);
  const thumbnailRowRef: any = useRef(null);

  /* onThumbnail */
  function onThumbnail(source: string, type: string, event: any,) {
    event.persist();

    if (type === 'image') {
      // Set Image
      setState(showImageContainerState = true);
      primaryImageRef.current.src = source;
      setModalImage(modalImage = source);
    } else if (type === 'video') {
      // Set Video
      setState(showImageContainerState = false);
      primaryVideoRef.current.src = source;
    }
  }


  function scrollLeft() {
    thumbnailRowRef.current.scrollBy(100, 0);
  }

  function scrollRight() {
    thumbnailRowRef.current.scrollBy(-100, 0);
  }


  function setDefaultImage(source: string) {
    setTimeout(() => {
      if (!primaryImageRef.current.src) {
        primaryImageRef.current.src = source;
        setModalImage(modalImage = source);
      }
    }, 0)
  }

  function setDefaultVideo(source: string) {
    setTimeout(() => {
      if (!primaryVideoRef.current.src) {
        setState(showImageContainerState = false);
        primaryVideoRef.current.src = source;
      }
    }, 0)
  }

  const contentRef = useRef(null);
  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current.scrollToPoint(0, 0);
  };

  if (loaded) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent scrollEvents={true} ref={contentRef}>
          <div className="content-margin">
            <IonGrid className="mb-5">
              {/* Back Button */}
              <div className="container">
                <div className="row">
                  <div className="col-12 d-print-none">
                    <IonButton
                      id={"BotDetail-Back-" + botId}
                      className="back-button"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <IonIcon icon={arrowRoundBack}></IonIcon>
                      <span className="px-2 font-weight-bold">Go back</span>
                      <span className="back-button-end-line"></span>
                    </IonButton>
                  </div>
                </div>
              </div>
              {/* Description Card */}
              <div className="container shadow-sm border px-5 py-2 bot-details-wrapper">
                {/* Header Name */}
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <IonCardHeader>
                      <IonCardTitle className="text-center">
                        <div className="text-uppercase bot-name">
                          {BotDetail.BotName}
                        </div>
                        <div className="small-hrzLine"></div>
                      </IonCardTitle>
                    </IonCardHeader>
                  </div>
                  {/* Inquire Button */}
                  <div className="col-1 pt-2">

                    <img src={downloadButtonPath} className="download-button-img d-print-none pointer" onClick={() => {
                      window.print();
                    }} alt="Print"></img>
                  </div>
                  <div className="col-2 pt-2">
                    <IonButton
                      size="small"
                      className="bot-detail-enqire button-size d-print-none"
                      id={"BotDetail-Enquire-" + botId}
                      color="primary"
                      fill="solid"
                      onClick={() => {
                        window.location.href = `mailto:dftebotstore@deloitte.com?subject=` + BotDetail.Category + ` – ` + BotDetail.BotName + ` Interest&body=Hi team, I’m interested in learning more about ` + BotDetail.BotName + `. Describe Need:`;
                      }}
                    >
                      INQUIRE
                    </IonButton>
                  </div>
                  <div className="col-6">
                    {/* Primary Image Container */}
                    <img ref={primaryImageRef}
                      onClick={handleShow}
                      className="rounded-lg pointer"
                      alt="Primary"
                      style={{ display: showImageContainerState ? "block" : "none" }}
                    />

                    {/* Primary Video Container */}
                    <video controls width="100%" ref={primaryVideoRef}
                      className="rounded-lg"
                      // src=""
                      style={{ display: !showImageContainerState ? "block" : "none" }}>
                    </video>

                    {/* Image Modal */}
                    <Modal show={show} onHide={handleClose} dialogClassName="image-modal-dialog">
                      <Image src={modalImage} fluid />
                    </Modal>
                  </div>

                  {/* Technical Details */}
                  <div className="col-6">
                    <div className="mb-4">
                      <p className="font-italic">Please note that a level of monetization will be applied based on size of account and program.</p>
                    </div>
                    <div className="pb-5">{BotDetail.TechnicalDetails}</div>
                    {/* Thumbnails */}
                    <div className="row mb-4">
                      {/* Backward Arrow */}
                      <span className="arrowIconWrap"><img src={arrowBackSvg} alt="Backward Arrow" className="arrowBackSvgWrapper" onClick={scrollRight} /></span>
                      <div className="row thumbnails-wrapper" ref={thumbnailRowRef}>
                        {carouselAttachments.map((element: any, index: number) => {
                          if (element.type === 'image') {
                            if (index === 0) {
                              setDefaultImage(element.src)
                            }
                            return <img
                              key={index}
                              src={element.src}
                              alt="thumbnail"
                              className="img-thumbnail w-25 m-2"
                              onClick={(event) => onThumbnail(element.src, element.type, event)}
                            />
                          } else if (element.type === 'video') {
                            if (index === 0) {
                              setDefaultVideo(element.src)
                            }
                            return <img
                              key={index}
                              src={videoThumbnail}
                              alt="thumbnail"
                              className="img-thumbnail w-25 m-2"
                              onClick={(event) => onThumbnail(element.src, element.type, event)}
                            />
                          }
                          return null;
                        })}
                      </div>
                      {/* Forward Arrow  */}
                      <span className="arrowIconWrap"><img src={arrowForwardSvg} alt="Forward Arrow" className="arrowForwardSvgWrapper" onClick={scrollLeft} /></span>
                    </div>
                    {/* Platform Images */}
                    <div className="row">
                      <div className="col-12">
                        {displayAutomationAnywhere && <span className="botDetails-platform-wrapper">
                          <img src={automationAnywhere} className="botDetails-platform-img" alt="Automation"></img>
                        </span>}
                        {displayBluePrism && <span className="botDetails-platform-wrapper ml-3">
                          <img src={bluePrism} className="botDetails-platform-img" alt="Blue Prism"></img>
                        </span>}
                        {displayUiPath && <span className="botDetails-platform-wrapper ml-3">
                          <img src={uiPath} className="botDetails-platform-img" alt="UI"></img>
                        </span>}
                        {displayiRPA && <span className="botDetails-platform-wrapper ml-3">
                          <img src={iRPA} className="botDetails-platform-img" alt="iRPA"></img>
                        </span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="description-divider"></div>
                {/* Description */}
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className="font-weight-bold">DESCRIPTION</span>
                    <p className="mt-2 pre-line">{BotDetail.Description}</p>
                  </div>
                </div>
                {/* Contributors */}
                <div className="row">
                  <div className="col-8 pb-2">
                    Contributors: {BotDetail.Contributers}
                  </div>
                  <div className="col-4 py-2 text-right">
                    Past Projects: {BotDetail.PastProjects}
                  </div>
                </div>
                {/* Tags */}
                <div className="row">
                  <div className="col-8 pb-2">
                    Tags:
                    <IonCol className="benefits">
                      {tagList === undefined
                        ? ""
                        : Object.values(tagList).map((value, index) => {
                          return (
                            <IonChip key={index}>
                              <IonLabel>{value}</IonLabel>
                            </IonChip>
                          );
                        })}
                    </IonCol>
                  </div>
                  <div className="col-4 py-2 text-right">
                    Published: {BotDetail.PublishedDate}
                  </div>
                </div>
              </div>
            </IonGrid>
          </div>
          <div className="content-gray d-print-none">
            <div className="content-margin py-5">
              <div className="popBots">SIMILAR BOTS</div>
              <div className="populerbot-section">
                <div className="row">
                  {similarBots.slice(0, 5).map((value, index) => {
                    return (
                      <div onClick={() => scrollToTop()}>
                        <BotCard
                          id={(value._id as unknown) as string}
                          botName={value.BotName}
                          description={value.TechnicalDetails}
                          likes={value.Likes}
                          stars={value.StarRating}
                          views={value.NoOfPeopleViewed}
                          platforms={value.hasOwnProperty('Platforms') && value.Platforms.length > 0 ? value.Platforms : []}
                          subCategory={value.SubCategory}
                        //rank={value.Rank}
                        ></BotCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <DTFooter />
        </IonContent>
      </IonPage>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default BotDetail;
