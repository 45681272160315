import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import "../App.css";
import "./BotInfoCard.css"
import { contact } from "ionicons/icons";
import Axios from "axios";
import { botStruct } from "../pages/Type";
import {apiURL} from "../Services/config"

const apiEndPoint = apiURL;
interface inputs
{
    username:string;
    publishedbotcount:string;
    description:string;
}

const UserTag: React.FC<inputs>=input=>{
    
    const [allbot,setallbot]=useState([]);
    const [loaded,setloaded]=useState(false);
    useEffect(()=>
    {
        Axios.get(apiEndPoint + 'api/getBots').then(res=>setallbot(res.data))
        setloaded(true)
    },[])

const bots:botStruct[]=allbot as unknown as botStruct[];
const userBot=bots.filter(t=>t.Contributers.indexOf(input.username)>-1)
const count=userBot.length;

    if(loaded){
    return(
        <IonCard>
            <div className="info-card user-info-card">
            <IonGrid>
                <IonRow>
                    <IonCol className="icon-container" size="2">
                        <IonIcon className="usertag-icon" icon={contact}></IonIcon>
                    </IonCol>
                    <IonCol size="10">
                        <IonCardTitle className="title">
                        {input.username}
                        </IonCardTitle>
    <div className="card-line"><div className="detailing card-label">Published </div>: <div className="detailing card-detail">{count} {count>1?"Bots":"Bot"}</div></div>
                        
                    </IonCol>
                </IonRow>
                <IonRow>
    <p className="card-label">{input.username} has been with Deloitte for 7 years specializing in Digital Transformations and RPA Centers of Excellence</p>
                </IonRow>
            </IonGrid>
            </div>
        </IonCard>
    );
    }else{return(<div>Loading...</div>)}
}
export default UserTag;