import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonHeader,
  IonTitle,
  IonButton,
  IonImg
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import "./Offerings.css";
import images from "../images";
import DTFooter from "../Components/footer";
import BotCard from "../Components/BotCard";
import "../App.css";
import axios from 'axios';
import "./Home.css";
import useAnalytics from '../Components/useAnalytics';
//import { Data } from "react-horizontal-scrolling-menu/build/types";
import { useSelector, useDispatch } from 'react-redux';
import { botStruct } from './Type';
import { allbots } from "../actions/index.js";
import { apiURL } from "../Services/config"
import CustomTree from "../Components/CustomTree";

window.name = ""
const apiEndPoint = apiURL;
const ProcessAreas: React.FC = () => {

  useAnalytics(null);
  const subcatdata: any = useSelector<any>(state => state.selectsubcat) || '';
  const botCollection = useSelector<any>(state => state.allbots as unknown as botStruct[]) || '';
  const dispatch = useDispatch();
  var ProcessBotsFromApi: botStruct[];
  ProcessBotsFromApi = botCollection as unknown as botStruct[];
  const [allbot, setallbots] = useState([])
  if (ProcessBotsFromApi.length === 0) {

    dispatch(allbots(allbot));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subJson, setsubJson] = useState([]);
  const [loaded, setloaded] = useState(false);

  console.log(ProcessBotsFromApi);
  console.log(botCollection);

  var subcattemp = subcatdata.split('+');

  const [subcatdesc, setsubcatdesc] = useState('');

  useEffect(() => {
    if (subcatdata === "") {
      axios.get(apiEndPoint + 'api/getSubCategoriesByCategory/Tech Areas').then(res => { setsubJson(res.data) })
    }
    if (ProcessBotsFromApi.length === 0) {
      axios.get(apiEndPoint + 'api/getBots').then(res => setallbots(res.data))

    }
    if (subcatdata !== "") {
      axios.get(apiEndPoint + 'api/getProposedContent/Tech Areas/' + subcatdata).then(res => setsubcatdesc(res.data))
    }
    setloaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcatdata])

  ProcessBotsFromApi = (subcatdata === "" ? ProcessBotsFromApi.filter(t => t.Category === "Process Areas") : ProcessBotsFromApi.filter((t: any) => t.SubCategory === subcattemp[0]))
  ProcessBotsFromApi = ProcessBotsFromApi.filter(value => value.BotName !== "")

  var botcount = ProcessBotsFromApi.length;
  var header = subcatdata === "" ? "PROCESS AREAS PORTFOLIO" : subcattemp[0];
  var description = subcatdata !== "" ? subcatdesc :
    "Functional teams can utilize Deloitte pre-built bots to automate their business processes. Leveraging these bots can substantially increase process efficiencies in the areas of Finance, Human Resources, Marketing, Operations & Supply Chain, Procurement, Sales, PMO and more. Click the View Bots button below to browse the SAP Process Areas bot portfolio.";
  const contentRef = useRef(null);
  const scrollToPoint = (elementId: string) => {
    // @ts-ignore
    contentRef.current.scrollToPoint(0, document.getElementById(elementId).offsetTop - 50);
  };

  const SubCategoryForFilter: any = useSelector<any>(state => state.GetCatSubCat) || '';
  if (SubCategoryForFilter !== "") {
    var CatSubCat = SubCategoryForFilter.split('/');
    ProcessBotsFromApi = ProcessBotsFromApi.filter((t: any) => t.Category === CatSubCat[0] && t.SubCategory === CatSubCat[1]);
    botcount = ProcessBotsFromApi.length;
  }
  if (loaded) {
    return (
      <IonPage>
        <IonHeader >
          {/* <IonToolbar className="page-subheader"> */}
          <IonTitle className="page-subheader-techAreas">
            {" "}
            {/* <SubHeader list={Data}/> */}
          </IonTitle>
          {/* </IonToolbar> */}
        </IonHeader>

        <IonContent scrollEvents={true} ref={contentRef}>
          <div className="content-margin">
            <IonGrid>
              <IonRow>
                <IonCol size-xs="12" size-sm="12" size-lg="6">
                  <div className="main-card"><IonLabel className="offering-Header">
                    {header}
                  </IonLabel></div>
                  <br />
                  <p className="offering-cardcontent">
                    {description}
                  </p>
                  <br />
                  <IonButton
                    size="small"
                    color="primary"
                    className="offering-button button-size"
                    fill="solid"
                    onClick={() => scrollToPoint("process-bots")}
                  >
                    VIEW BOTS
                </IonButton>
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-lg="6">
                  <IonImg className="offering-image" src={images[10].src}></IonImg>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel className="offering-Header">
                    BOTS PORTFOLIO
              </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>

                <IonCol size-xs="12" size-sm="12" size-lg="3">
                  <CustomTree ProcessAreaDisplay={true} TechAreaDisplay={false} PlatformDisplay={true} SDLCPhaseVisible={true}></CustomTree>
                </IonCol>
                <IonCol size-xs="6" size-sm="6" size-lg="9">
                  <div className="bots-count">{botcount} results</div>
                  {ProcessBotsFromApi.map((value: any) => {
                    return (

                      <div>
                        <BotCard
                          id={value._id as unknown as string}
                          description={value.TechnicalDetails}
                          botName={value.BotName}
                          likes={value.Likes}
                          views={value.NoOfPeopleViewed}
                          /* rank={value.Rank} Temporarily disabling(Need to add rank in Master JSON)*/
                          platforms={value.hasOwnProperty('Platforms') && value.Platforms.length > 0 ? value.Platforms : []}
                          sdlcPhase={value.hasOwnProperty('SDLCPhase') && value.SDLCPhase !== null && value.Platforms.length > 0 ? value.SDLCPhase : []}
                          stars={value.StarRating}
                          subCategory={value.SubCategory}
                        ></BotCard>
                      </div>

                    );
                  })}
                </IonCol>

              </IonRow>
            </IonGrid>
          </div>
          <DTFooter />
        </IonContent>
      </IonPage>
    );
  } return (<div>Loading...</div>)
};

export default ProcessAreas;
