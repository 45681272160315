import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore} from 'redux';
import allReducers from './reducers/index.js';
import {Provider} from 'react-redux';
import { runWithAdal } from "react-adal";
import 'bootstrap/dist/css/bootstrap.min.css';

import adalContext from "./Services/adalConfig"; 
window.name="";
const store=createStore(allReducers);

runWithAdal(adalContext.AuthContext, () => { 
	ReactDOM.render(
		<Provider store={store}>
		<App />
		</Provider>,
		 document.getElementById('root'));
}, false); 


