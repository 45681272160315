import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import BotModel from '../Models/BotModel'
import { IonContent, IonButton, IonGrid, IonCol, IonRow, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel } from "@ionic/react";
import { RouteComponentProps, withRouter } from 'react-router';
import User from '../Components/ValidateAdmin.js'
import {apiURL} from "../Services/config"

const apiEndPoint = apiURL;
const Admin:React.FC<RouteComponentProps> = ({history})=>{
    const [bots, setBots] = useState([{} as BotModel])
    const [filteredBots, setFilteredBots] = useState([{} as BotModel])
    const [searchTerm, setSearchTerm] = useState("");
    useEffect(()=>{
        Axios.get(apiEndPoint + "api/getBots").then(res=>{
            setBots(res.data)
            setFilteredBots(res.data)
		});
    },[])
    useEffect(()=>{
        if(searchTerm!==""){
           var results = filteredBots.filter(bot =>
              (bot.Category != null && bot.SubCategory!=null && bot.Contributers!=null && 
                bot.BotName!=null &&  bot.TechnicalDetails!=null && bot.Description!=null && bot.Tags!=null &&  bot.ProposedContent!=null) &&
              (bot.Category.toLowerCase().includes(searchTerm) ||
              bot.SubCategory.toLowerCase().includes(searchTerm) ||
              bot.Contributers.toLowerCase().includes(searchTerm) ||
              bot.BotName.toLowerCase().includes(searchTerm) ||
              bot.TechnicalDetails.toLowerCase().includes(searchTerm) ||
              bot.Description.toLowerCase().includes(searchTerm) ||
              bot.Tags.toLowerCase().includes(searchTerm) ||
              bot.ProposedContent.toLowerCase().includes(searchTerm))
           );
           setBots(results);
        } else {
            setBots(filteredBots);
        }
    },[searchTerm, filteredBots])
   
    if(User.isAdmin){
        return(
            <>
                <IonContent>
                    <div className="content-margin">
                        <IonButton color="success" onClick={() => {history.push("/createBot");}}>Create Bot</IonButton>
                            <IonItem>
                            <IonLabel>
                               <h1> All Bots  - {bots.length}</h1>
                            </IonLabel>
                        </IonItem>
                    <IonSearchbar value={searchTerm} placeholder="Filter Bots" animated color="danger" onIonChange={e => setSearchTerm(e.detail.value!)}></IonSearchbar>    
                    <IonGrid>
                        <IonRow>
                        {
                            bots.map(bot=>(
                                <IonCol key={bot._id} sizeLg="6">
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>{bot.BotName}</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <h2>Description:</h2>
                                            <div>{bot.Description}</div>
                                            <br/>
                                            <h2>Tags:</h2>
                                            <div>{bot.Tags}</div>
                                            <IonButton color="secondary" onClick={()=>history.push(`/viewBot/${bot._id}`)}>View / Edit / Remove</IonButton>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                )
                            )
                        }
                        </IonRow>
                    </IonGrid>
                    </div>
                </IonContent>
            
            </>
            )
    } else {
        return(
            <IonContent>
                <div className="content-margin">
                    <h2>Error message: You are not authorized to access this page</h2>
                </div>
            </IonContent>
        )
    }
}
export default withRouter(Admin)