import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonImg
} from "@ionic/react";
import React ,{useState}from "react";
import "./Offerings.css";
import images from "../images";
import DTFooter from "../Components/footer";
import Data from "../data";
import BotCard from "../Components/BotCard";
import "../App.css";
import "./Home.css";
import { RouteComponentProps } from "react-router";



  interface BotDetail
  extends RouteComponentProps<{
    id: string;
  }> {}
const ProcessAreas = ({match,history}:BotDetail) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchKey,setSearchKey]=useState(window.name);

  const subCatId=match.params.id;
  console.log(subCatId);

  const seperate=subCatId.split('+');
  const catId =seperate[1] as unknown as number;
  const catIdasString=seperate[1];
  
  var tempSubCatId:string;
  const subCat=Data.masterSubCategory.filter(t=>t.catId===catId)
  subCat.forEach((t)=>{
    if(t.menuName===seperate[0]){t.selected=true;tempSubCatId=t.id}
  })
  
  const specificBot=Data.BotDetailsMaster.filter(t=>t.CategoryId===catIdasString && t.SubCategoryId===tempSubCatId)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="page-subheader">
          <IonTitle>
            {" "}
           {/* <SubHeader list={subCat} /> */}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="content-margin">
          <IonGrid>
            <IonRow>
              <IonCol size-xs="12" size-sm="12" size-lg="6">
              <div className="main-card"><IonLabel className="offering-Header">
                {seperate[0]}
                </IonLabel></div>
                <p className="offering-cardcontent">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form , by injecting humour, randomised words which dont look
                  even slightly believable.
                </p>
                <IonButton
                  size="small"
                  color="primary"
                  className="offering-button button-size"
                  fill="solid"
                >
                  VIEW BOTS
                </IonButton>
              </IonCol>
              <IonCol size-xs="12" size-sm="12" size-lg="6">
                <IonImg className="offering-image" src={images[0].src}></IonImg>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel className="offering-Header content-margin">
                BOTS PORTFOLIO
              </IonLabel>
            </IonCol>
          </IonRow>
          <div className="content-gray">
            <div className="content-margin">
              <IonRow>
                <div className="bots-count">{specificBot.length} results</div>
                {specificBot.map(value => {
                  return (
                    <IonCol size-sm="6" size-lg="3">
                      <div>
                        <BotCard
                          id={value.id as unknown as string}
                          description={value.TechnicalDetails}
                          botName={value.BotTitle}
                          likes={value.Likes}
                          views={value.ViewedNumbers}
                         /* rank={value.Rank} Temporarily disabling(Need to add rank in Master JSON)*/
                          stars={value.StarRating}
                        ></BotCard>
                      </div>
                    </IonCol>
                  );
                })}
              </IonRow>
            </div>
          </div>
        </IonGrid>
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};

export default ProcessAreas;
