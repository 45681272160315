import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonImg
} from "@ionic/react";
import React, { useState } from "react";
import "./Offerings.css";
import images from "../images";
import Data from "../data";
import BotCard from "../Components/BotCard";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
var botCount: number = 0;

const Offerings: React.FC = () => {
  useState({
    loaded: false,
    error: false,
  })


  /* useEffect(() => {
    let script = document.createElement("script")
    script.src = "//assets.adobedtm.com/571f1a0c43d6/6f36de539033/launch-9e78a917adc3-staging.min.js";
    script.async = true;
    const onScriptLoad = () => {
      setState({
          loaded: true,
          error: false,
      })
  }

  const onScriptError = () => {
      // Remove from cachedScripts we can try loading again
      const index = cachedScripts.indexOf(script.src);
      if (index >= 0) cachedScripts.splice(index, 1)
      script.remove()

      setState({
          loaded: true,
          error: true,
      })
  }

  script.addEventListener("load", onScriptLoad)
  script.addEventListener("error", onScriptError)

  // Add script to document body
  document.body.appendChild(script)

  // Remove event listeners on cleanup
  return () => {
      script.removeEventListener("load", onScriptLoad)
      script.removeEventListener("error", onScriptError)
  }
    console.log('mount it!');
}, []); */
  /* const subCat: {menuName: string, menuId:string,selected:boolean}[] = [
    {menuName:"Delivery Excellence",menuId:"Delivery-Excellence",selected:false},
    {menuName:"Sales Excellence",menuId:"Sales-Excellence",selected:false},
    {menuName:"Ecosystems and Alliance",menuId:"Ecosystems-and-Alliance",selected:false},
    {menuName:"Platforms and Assets",menuId:"Platforms-and-Assets",selected:false},
    {menuName:"Talents and Inclusion",menuId:"Talents-and-Inclusion",selected:false},
    
  ]; */
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="page-subheader">
          <IonTitle>
            {" "}
            {/* <SubHeader list={subCat}/> */}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="content-margin">
          <IonGrid>
            <IonRow>
              <IonCol size-xs="12" size-sm="12" size-lg="6">
                <div className="main-card"><IonLabel className="offering-Header">
                  OFFERINGS PORTFOLIO
                </IonLabel>
                </div>
                <p className="offering-cardcontent">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form , by injecting humour, randomised words which dont look
                  even slightly believable.
                </p>
                <IonButton
                  size="small"
                  color="primary"
                  className="offering-button button-size"
                  fill="solid"
                >
                  VIEW BOTS
                </IonButton>
              </IonCol>
              <IonCol size-xs="12" size-sm="12" size-lg="6">
                <IonImg className="offering-image" src={images[0].src}></IonImg>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel className="offering-Header content-margin">
                BOTS PORTFOLIO
              </IonLabel>
            </IonCol>
          </IonRow>
          <div className="content-gray">
            <div className="  content-margin">
              <IonRow>
                <div className="bots-count">{botCount} results</div>
                {Data.BotDetailsMaster.map(value => {
                  return (
                    <IonCol size-sm="6" size-lg="3">
                      <div>
                        <BotCard
                          id={value.id as unknown as string}
                          description={value.BotDescription}
                          botName={value.BotTitle}
                          likes={value.Likes}
                          views={value.ViewedNumbers}
                          //rank={value.Rank}
                          stars={value.StarRating}
                        ></BotCard>
                      </div>
                    </IonCol>
                  );
                })}
              </IonRow>
            </div>
          </div>
        </IonGrid>
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};

export default Offerings;
