import { SasBlobUrl } from '../Services/config';
// import {BlobServiceClient, ContainerURL, ServiceURL,} from "@azure/storage-blob";
 const { BlobServiceClient, } = require("@azure/storage-blob");

const reportStatus = (message:any) => {
    console.log(message);
}

const blobSasUrl = SasBlobUrl;

// Create a new BlobServiceClient
const blobServiceClient = new BlobServiceClient(blobSasUrl);

export const createContainer = async (containerName:any) => {
    try {
        let containerClient = blobServiceClient.getContainerClient(containerName);        
        reportStatus(`Creating container "${containerName}"...`);
        await containerClient.create();
        reportStatus(`Done.`);
    } catch (error) {
        reportStatus(error.message);
    }
};

export const deleteContainer = async (containerName:any) => {
    try {
        let containerClient = blobServiceClient.getContainerClient(containerName);
        reportStatus(`Deleting container "${containerName}"...`);
        await containerClient.delete();
        reportStatus(`Done.`);
    } catch (error) {
        reportStatus(error.message);
    }
};


export const listFiles = async (containerName:any) => {
    
    try {
        let containerClient = blobServiceClient.getContainerClient(containerName);
        reportStatus("Retrieving file list...");
        let iter = containerClient.listBlobsFlat();
        let blobItem = await iter.next();
        while (!blobItem.done) {
           
            blobItem = await iter.next();
        }
    } catch (error) {
        reportStatus(error.message);
    }
};

// const uploadFiles = async (containerName:any, fileinput:any[]) => {
//     try {
//         reportStatus("Uploading files...");
//         const promises = [];
//         for (const file of fileInput) {
//             const blockBlobClient = containerClient.getBlockBlobClient(file.name);
//             promises.push(blockBlobClient.uploadBrowserData(file));
//         }
//         await Promise.all(promises);
//         reportStatus("Done.");
//         listFiles(containerName);
//     }
//     catch (error) {
//             reportStatus(error.message);
//     }
// }

export const deleteFiles = async (containerName:any,image:any) => {
    try {
        let containerClient = blobServiceClient.getContainerClient(containerName);
        containerClient.deleteBlob(image);
        // listFiles(containerName);
        // if (fileList.selectedOptions.length > 0) {
        //     reportStatus("Deleting files...");
        //     for (const option of fileList.selectedOptions) {
        //         await containerClient.deleteBlob(option.text);
        //     }
        //     reportStatus("Done.");
        //     listFiles(containerName);
        // } else {
        //     reportStatus("No files selected.");
        // }
    } catch (error) {
        reportStatus(error.message);
    }
};

// export const downloadFile = async()=>{
//     try{
//         const containerURL =  ContainerURL.fromServiceURL(serviceURL, containerName);
//         const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, blobName);

//         const downloadResponse = await blockBlobURL.download(aborter, 0);
//         const downloadedContent = await streamToString(downloadResponse.readableStreamBody);
//         console.log(`Downloaded blob content: "${downloadedContent}"`);
//     }
//     catch(error){
//         reportStatus(error.message);
//     }
// };