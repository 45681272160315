import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import BotModel from '../Models/BotModel'
import Axios from 'axios'
import { IonList, IonItem, IonLabel, IonContent, IonButton, IonToast, IonCheckbox, IonRadioGroup, IonRadio } from '@ionic/react'
import isAdmin from '../Components/ValidateAdmin.js';
import { apiURL } from "../Services/config"

const apiEndPoint = apiURL;
const ViewBot: React.FC<RouteComponentProps<{ id: string }>> = ({ history }) => {
    const [bot, setBot] = useState({} as BotModel)
    const [showToast, setShowToast] = useState(false);
    const [checkedUIPath, setCheckedUIPath] = useState(false);
    const [checkedAutomationAnywhere, setCheckedAutomationAnywhere] = useState(false);
    const [checkedBluePrism, setCheckedBluePrism] = useState(false);
    const [checkediRPA, setCheckediRPA] = useState(false);
    const [isConfigurationChecked, setCheckedConfiguration] = useState(false);
    const [isDesignChecked, setCheckedDesign] = useState(false);
    const [isBuildChecked, setCheckedBuild] = useState(false);
    const [isTestChecked, setCheckedTest] = useState(false);
    const [isCutoverChecked, setCheckedCutover] = useState(false);
    const [isBusinessProcessChecked, setCheckedBusinessProcess] = useState(false);
    const [menu, setMenuOption] = useState<string>('');

    const createBot = () => {
        bot.PublishedDate = new Date().toLocaleString() as any;
        setPlatformData();
        setSDLCPhaseData();
        setMainMenuOption();
        Axios.post(apiEndPoint + `api/createBot/`, bot).then(() => {
            setShowToast(true);
            setTimeout(() => {
                history.push('/')
            }, 2000);
        })
    }

    const setMainMenuOption = () => {
        bot.Type = '';
        if (menu !== '') {
            bot.Type = menu;
        }
    }
    const setPlatformData = () => {
        bot.Platforms = [];
        if (checkedUIPath) {
            bot.Platforms.push("UIPath");
        }
        if (checkedAutomationAnywhere) {
            bot.Platforms.push("Automation Anywhere");
        }
        if (checkedBluePrism) {
            bot.Platforms.push("Blue Prism");
        }
        if (checkediRPA) {
            bot.Platforms.push('iRPA');
        }
    }
    const setSDLCPhaseData = () => {
        bot.SDLCPhase = [];
        if (isConfigurationChecked) {
            bot.SDLCPhase.push('Configuration');
        }
        if (isDesignChecked) {
            bot.SDLCPhase.push('Design');
        }
        if (isBuildChecked) {
            bot.SDLCPhase.push('Build');
        }
        if (isTestChecked) {
            bot.SDLCPhase.push('Test');
        }
        if (isCutoverChecked) {
            bot.SDLCPhase.push('Cutover');
        }
        if (isBusinessProcessChecked) {
            bot.SDLCPhase.push('Business Process');
        }
    }
    if (isAdmin) {
        return (
            <IonContent>
                <div className="content-margin">
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={`${bot.BotName} has been created successfully.`}
                        duration={2000}
                    />
                    <IonList>
                        <IonItem>
                            <IonLabel>
                                Category:<sup>*</sup>
                            </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <input
                                    type="text"
                                    value={bot.Category}
                                    onChange={e => setBot({ ...bot, Category: e.target.value as any })}
                                />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Sub Category:<sup>*</sup>
                            </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.SubCategory}
                                    onChange={e => setBot({ ...bot, SubCategory: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Contributers:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Contributers}
                                    onChange={e => setBot({ ...bot, Contributers: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Bot Name:<sup>*</sup>
                            </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.BotName}
                                    onChange={e => setBot({ ...bot, BotName: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Technical Details:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.TechnicalDetails}
                                    onChange={e => setBot({ ...bot, TechnicalDetails: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Tags:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Tags}
                                    onChange={e => setBot({ ...bot, Tags: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                System Prerequisites:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.SystemPrerequisites}
                                    onChange={e => setBot({ ...bot, SystemPrerequisites: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Description:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Description}
                                    onChange={e => setBot({ ...bot, Description: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Proposed Content:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.ProposedContent}
                                    onChange={e => setBot({ ...bot, ProposedContent: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Past Projects:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.PastProjects}
                                    onChange={e => setBot({ ...bot, PastProjects: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Platforms:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                {/* {checkboxList.map(({ val, isChecked }, i) => (
                        <IonItem key={i}>
                        <IonLabel>{val}</IonLabel>
                        <IonCheckbox slot="end" value={val} checked={isChecked} 
                        // onChange={e=>setBot({ ...bot, ProposedContent:e.target.value as any})} />
                        onIonChange={e => setChecked(e.detail.checked)} />
                        </IonItem>
                    ))} */}

                                <IonItem key="1">
                                    <IonLabel>UIPath</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedUIPath}
                                        onIonChange={e => setCheckedUIPath(e.detail.checked)} />
                                </IonItem>

                                <IonItem key="2">
                                    <IonLabel>Automation Anywhere</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedAutomationAnywhere}
                                        onIonChange={e => setCheckedAutomationAnywhere(e.detail.checked)} />
                                </IonItem>

                                <IonItem key="3">
                                    <IonLabel>Blue Prism</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedBluePrism}
                                        onIonChange={e => setCheckedBluePrism(e.detail.checked)} />
                                </IonItem>
                        <IonItem key="4">
                            <IonLabel>iRPA</IonLabel>
                            <IonCheckbox slot="end" value="iRPA" checked={checkediRPA}                            
                            onIonChange={e => setCheckediRPA(e.detail.checked)} />
                        </IonItem>
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>SDLC Phase</IonLabel>
                            <IonLabel class='fixedLabel'>
                                <IonItem key="5">
                                    <IonLabel>Configuration</IonLabel>
                                    <IonCheckbox slot="end" value="Configuration" checked={isConfigurationChecked}
                                        onIonChange={e => setCheckedConfiguration(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="6">
                                    <IonLabel>Design</IonLabel>
                                    <IonCheckbox slot="end" value="Design" checked={isDesignChecked}
                                        onIonChange={e => setCheckedDesign(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="7">
                                    <IonLabel>Build</IonLabel>
                                    <IonCheckbox slot="end" value="Build" checked={isBuildChecked}
                                        onIonChange={e => setCheckedBuild(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="8">
                                    <IonLabel>Test</IonLabel>
                                    <IonCheckbox slot="end" value="Test" checked={isTestChecked}
                                        onIonChange={e => setCheckedTest(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="9">
                                    <IonLabel>Cutover</IonLabel>
                                    <IonCheckbox slot="end" value="Cutover" checked={isCutoverChecked}
                                        onIonChange={e => setCheckedCutover(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="10">
                                    <IonLabel>Business Process</IonLabel>
                                    <IonCheckbox slot="end" value="BusinessProcess" checked={isBusinessProcessChecked}
                                        onIonChange={e => setCheckedBusinessProcess(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Type:
                            </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <IonRadioGroup value={menu} onIonChange={e => setMenuOption(e.detail.value)}>
                                    <IonItem>
                                        <IonLabel>Commercial</IonLabel>
                                        <IonRadio slot="start" value="Commercial" />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>GPS</IonLabel>
                                        <IonRadio slot="start" value="GPS" />
                                    </IonItem>
                                </IonRadioGroup>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                    <IonButton color="primary" disabled={!bot.BotName || !bot.Category || !bot.SubCategory} onClick={() => createBot()}>Create Bot</IonButton>
                </div>
            </IonContent>
        )
    } else {
        return (
            <IonContent>
                <div className="content-margin">
                    <h2>Error message: You are not authorized to access this page</h2>
                </div>
            </IonContent>
        )
    }
}
export default ViewBot
