import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./Offerings.css";
import { arrowRoundBack } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
import "./SubmitIdeaScreen.css";
import BotCard from "../Components/BotCard";
import './SearchScreen.css';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import { botStruct } from './Type'
import { allbots } from "../actions";
import { apiURL } from "../Services/config"

const apiEndPoint = apiURL;
const SearchScreen = ({ history }: RouteComponentProps) => {
  const botCollection = useSelector<any>(state => state.allbots as unknown as botStruct[]) || '';
  var BotDetail: botStruct[] = botCollection as botStruct[];
  const dispatch = useDispatch();
  const searchKey: any = useSelector<string>(state => state.search) || '';
  const [allBots, setallBots] = useState([]);
  if (BotDetail.length === 0) {
    dispatch(allbots(allBots));
  }
  const [loaded, setloaded] = useState(false);
  const [subcategories, setsubcategories] = useState([]);
  const [processsubcategories, setprocesssubcategories] = useState([]);
  const goBack = () => {
    window.name = "";
    history.goBack();
  }
  var Categories = ["Tech Areas", "Process Areas"];
  useEffect(() => {
    if (BotDetail.length === 0) {
      Axios.get(apiEndPoint + 'api/getBots').then(res => setallBots(res.data))
    }
    Axios.get(apiEndPoint + 'api/getSubCategoriesByCategory/Tech Areas').then(res => setsubcategories(res.data))
    Axios.get(apiEndPoint + 'api/getSubCategoriesByCategory/Process Areas').then(res => setprocesssubcategories(res.data))
    setloaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  var SubCategory: string[];
  const getSubCategories = (category: string) => {
    category === "Tech Areas" ? SubCategory = subcategories as unknown as string[] : SubCategory = processsubcategories as unknown as string[];
    return SubCategory;
  }
  var result = BotDetail.filter(t => ((t.BotName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) || t.Category.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) || t.SubCategory.toLowerCase().indexOf(window.name.toLowerCase()) > -1
    || t.Tags.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 || t.TechnicalDetails.toLowerCase().indexOf(searchKey.toLowerCase) > -1);


  if (loaded) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <div className="content-margin">
              <IonButton
                fill="clear"
                className="submitHeader"
                onClick={() => goBack()}
              >
                {" "}
                <IonIcon
                  id="return-home-from-submit-screen"
                  icon={arrowRoundBack}
                  className="back-icon search-back"
                  onClick={() => history.push("/home")}
                >
                  {" "}
                </IonIcon>{" "}
                <p className="header-text">SEARCH RESULTS</p>
              </IonButton>

              <div className="main-container">
                <IonRow>
                  <IonCol size-xs="12" size-sm="12" size-lg="12">
                    <IonRow className="inputRow">


                      {
                        Categories.map(t => {

                          var tempsubCat = getSubCategories(t)
                          return tempsubCat.map(u => {
                            var tempBots = result.filter(z => z.Category === t && z.SubCategory === u)

                            return (tempBots.length === 0 ? "" :
                              <div className="categorizedRow">
                                <IonRow>
                                  { /* eslint-disable-next-line no-unused-expressions */}
                                  <IonLabel className="submitHeader">{t}>{u}</IonLabel>
                                </IonRow>
                                <IonRow>
                                  {tempBots.map(value => {
                                    return (
                                      <div>

                                        {/* <IonLabel>{t.CategoryName}>{u.menuName}</IonLabel> */}
                                        <IonCol size-sm="6" size-lg="3">

                                          <BotCard
                                            key={value._id}
                                            id={value._id as unknown as string}
                                            description={value.TechnicalDetails}
                                            botName={value.BotName}
                                            likes={value.Likes}
                                            views={value.NoOfPeopleViewed}
                                            /*rank={value.Rank}*/
                                            stars={value.StarRating}
                                          ></BotCard>

                                        </IonCol>

                                      </div>

                                    );
                                  })
                                  }
                                </IonRow>

                              </div>);
                          })
                        }
                        )
                      }


                    </IonRow>
                  </IonCol>
                </IonRow>
              </div>
            </div>
          </IonGrid>
          <DTFooter />
        </IonContent>
      </IonPage>
    );
  } else { return <div>Loading...</div> }
}

export default SearchScreen;
