import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonLabel, IonButton, IonIcon, IonModal } from '@ionic/react';
import "../App.css";
import "./footer.css";
import { Link } from 'react-router-dom'
import { close } from "ionicons/icons";

const DTFooter = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <IonGrid>
      <div className="footer-container d-print-none">
        <div className="content-margin">
          <IonRow>
            <IonCol size="6">
              <IonRow className="">
                <img decoding="async" src="./assets/deloitteLogo.jpg" alt="Deloitte Logo"></img>
              </IonRow>
              <IonRow><div className="header-tab"><p>© 2020 Deloitte Inc. All rights reserved.</p></div></IonRow>
            </IonCol>
            <IonCol size="3">
              <IonLabel className="footerLabel">PRODUCT/IDEAS</IonLabel>


              <IonRow>
                {/* <IonButton
                className="footerButtons header-tab"
                fill="clear"
                routerLink="TechAreas"
                href="/TechAreas"
                id="footer-techareas"
              >
                Tech Areas
              </IonButton> */}
              </IonRow>
              <IonRow>
                {/* <IonButton
                className="footerButtons header-tab"
                fill="clear"
                routerLink="ProcessAreas"
                href="/ProcessAreas"
                id="footer-processAreas"
              >
                Process Areas
              </IonButton> */}
                <IonButton
                  className="footerButtons header-tab"
                  fill="clear"
                  routerLink="ExploreBots"
                  href="/ExploreBots"
                  id="footer-processAreas"
                >
                  Explore Bots
              </IonButton>
              </IonRow>
              <IonRow>
                <IonButton
                  className="footerButtons header-tab"
                  fill="clear"
                  routerLink="SubmitIdeaScreen"
                  href="/SubmitIdeaScreen"
                  id="footer-submitIdeaScreen"
                >
                  Submit Idea
              </IonButton>
              </IonRow>
            </IonCol>
            <IonCol size="3">
              <IonLabel className="footerLabel ">Help</IonLabel>

              <IonRow>
                <Link to={'/FAQ'}> <IonButton id="footer-faq" className="footerButtons header-tab" fill="clear"  >
                  FAQ
              </IonButton>
                </Link>
              </IonRow>
              <IonRow>
                <IonButton id="footer-termsAndConditions" className="footerButtons header-tab" fill="clear" onClick={() => setShowModal(true)}>
                  Terms & Conditions
              </IonButton>
              </IonRow>
              <IonModal cssClass="TC_modal" id="modal" isOpen={showModal} onDidDismiss={() => { setShowModal(false) }}>
                <IonIcon className="TC_close" onClick={() => setShowModal(false)} icon={close}></IonIcon>
                <div className="TC_container">
                  {<p className="TC_header">TERMS & CONDITIONS</p>}
                  <p className="modal-message">This is a legal agreement between you ("User") and User's employer (or if User is a partner in an entity rather than an employee thereof, such entity) ("Employer") and one or more of the national member firms of Deloitte Touche Tohmatsu, a Swiss verein (together, "we" or "us"). This agreement governs use by Employer and User of the information, documents and other materials provided on our Digital Twin extranet site ("Digital Twin").
                  WHEREAS, Employer (or our client, where Employer is not our client) has requested that we grant User access to specific section(s) of Digital Twin (the "Accessible Sections"):
                  (i)     To facilitate our submission of proposals for the performance of professional services and facilitate communication thereon; and/or
                  (ii)     To provide User with confidential, proprietary and other information belonging to us and to third parties; and/or
                  (iii)     Where Employer is our client and we have agreed pursuant to an engagement letter(s) to provide services to Employer (the "Engagement Letter"), to facilitate our performance of services for or delivery to or receipt from Employer of deliverables (the "Engagement Deliverables") pursuant to such Engagement Letter(s);
                  (clauses (i) through (iii), collectively, the "Purpose"); and
                  WHEREAS, Employer (or our client, where Employer is not a client) has instructed us as to the extent of access to be granted to User; and
                  WHEREAS, we are willing to provide User with a login ID and a password (together, an "Access Code") to permit such access, on the terms and conditions set forth in this agreement.
                  NOW THEREFORE, the parties agree as follows:

                  1.       User name and password
                  a.       We agree to provide User with a unique Access Code to enable User to access Digital Twin. We may rescind User's access to Digital Twin in accordance with the terms of this agreement.
                  b.      User must keep User's Access Code confidential and must ensure that it is not disclosed to any other person.
                  c.       Employer and User are entirely responsible for any and all authorized and unauthorized use of User's Access Code. Employer or User must notify us immediately upon becoming aware of any unauthorized use of User's Access Codes or any other breach of security. Employer and User are further responsible for the actions of any person purporting to be User by using the Access Code issued to User, until such time as we have been notified in writing of the unauthorized use of such Access Code.
                  d.      User may only use the Access Code that has been assigned to User. Use by User of another person's Access Code is forbidden.
                  e.      User must immediately cease all use of his or her Access Code and Digital Twin upon the termination of his or her employment with Employer. Employer is responsible for all actions of User and any use of User's Access Code until Employer notifies us in writing of the termination of User's employment with Employer.
                  f.        The Access Code issued to User will allow access only to the Accessible Sections. User may not hack into or otherwise attempt to access other areas of Digital Twin to which User has not been granted access.

                  2.       Use of Digital Twin
                  a.       The Engagement Deliverables, and all use, modification and delivery thereof, shall be governed by the Engagement Letter pursuant to which they are used, modified or delivered, in addition to this agreement. In the event of conflict between the Engagement Letter and this agreement, the terms of the Engagement Letter shall control with respect to the Engagement Deliverables.
                  b.       Employer and User acknowledge that the Access Codes and the information and materials which appear on Digital Twin have tangible value and consist of various types of information and materials, some of which form part of our or third parties' confidential trade secrets and proprietary information (the "Deloitte Confidential Information"). This acknowledgement recognizes that material and information may or may not be protected by copyright laws and may include that of third parties that have given their permission to utilize the same.
                  c.       Unless otherwise notified, Employer and User may use Digital Twin and the Deloitte Confidential Information retrieved from Digital Twin in any way, provided that:
                  i.            Digital Twin and such Deloitte Confidential Information are only used in connection with the Purpose and in accordance with the terms and conditions of this agreement and, where applicable, the Engagement Letter; and
                  ii.           All printed and electronic copies of the Deloitte Confidential Information must retain and/or reflect any copyright or other legal notice that appears on such material when accessed on Digital Twin.
                  d.      Where User contributes information to Digital Twin or changes information located thereon (such information, the "Provided Information"), Employer and User agree:
                  i.            That it is Employer's and User's responsibility to ensure that the Provided Information is accurate and up-to-date and that any changes are appropriately authorized and that the Provided Information is free of viruses;
                  ii.           The Provided Information will be available, via Digital Twin, to other persons who Employer (or our client, where Employer is not a client) has authorized to access the Accessible Sections where the Provided Information is stored;
                  iii.          That the Provided Information will not be defamatory, indecent, infringe the intellectual property or data privacy rights of any third party or be otherwise unlawful;
                  iv.           That, although we have no obligation to verify the authenticity or timeliness or, unless otherwise agreed in accordance with the Engagement Letter, to review the nature or content of Provided Information, we may remove any Provided Information that we reasonably consider has been placed on Digital Twin in breach of this agreement or the Engagement Letter or is outdated and/or no longer relevant to users of Digital Twin; and
                  v.            That, although we will take reasonable steps in endeavoring to ensure the security of Digital Twin and the confidentiality of any Provided Information contributed to Digital Twin, we do not guarantee that Digital Twin or any third party websites connected via a hyperlink to or from Digital Twin ("Third Party Websites") will be secure.
                  e.      Other than as specified in this agreement, or an Engagement Letter, Employer and User may not make any other use of Digital Twin or any information or documentation extracted there from without our prior written permission. In particular, Employer and User may not:
                  i.            Copy, distribute, reproduce, modify, cache or archive the Deloitte Confidential Information in whole or in part except in connection with the Purpose;
                  ii.           Incorporate any material from Digital Twin in any other work, publication or website; or
                  iii.          License or sell Digital Twin and/or the Deloitte Confidential Information or otherwise make a profit on its use.
                  f.        If User accesses Digital Twin by downloading the Client Software plug-in from eRoom Technology Inc., Employer and User agree to be bound by their license terms, set out at http://documentum.com/legal/legal_disclaimer.html Digital Twin may be accessed without downloading the eRoom plug-in.
                  g.       Employer and User agree and acknowledge that the nature, content, scope and (if applicable) pricing of Digital Twin is Deloitte Confidential Information.

                  3.       Purpose of Digital Twin
                  a.       Employer and User acknowledge that no professional relationship of any nature is created solely by User's use of Digital Twin or through any correspondence or communication with us or any other party relating to Digital Twin.
                  b.      Notwithstanding Paragraph 3a above, any existing professional relationship will not be affected by User's use of Digital Twin.

                  4.       Links to third party websites
                  a.       Digital Twin may contain links to Third Party Websites over which we have no control. We make no representations or warranties as to the accuracy or legality of the contents of Third Party Websites and use of these Third Party Websites is at Employer's and User's own risk. We do not guarantee that any Third Party Websites will be free from viruses.
                  b.      The terms and conditions of this agreement do not apply to our other websites or services; use of such websites and services shall be governed by the terms and conditions for those websites and services.

                  5.       Amendments to this agreement
                  a.       We may at any time vary or amend these terms and conditions by written notice, which notice may be given electronically to User via Digital Twin. In particular, we and our affiliates reserve the right to introduce charges for access to new services in connection with Digital Twin. We will inform Employer or User of any such charges at least thirty days before we implement them.
                  b.      If User continues to use Digital Twin after Employer or User has been notified of any changes to the terms and conditions, Employer and User will be deemed to have accepted those changes.

                  6.       Changes or interruptions to Digital Twin
                  a.       We will endeavor to give Employer or User reasonable notice of any alterations or changes which are likely to materially affect the use of Digital Twin by posting an appropriate notice on Digital Twin. However, we reserve the right to amend the content or presentation of Digital Twin without notice and Employer and User acknowledge that access to Digital Twin may be suspended, limited, denied or disabled at any time and that information uploaded to Digital Twin may not be recoverable. Accordingly, Employer and User should have in place appropriate measures for ensuring the retention of copies of all such material. Employer and User agree that User will not use Digital Twin as a document repository.
                  b.      We are not responsible for the availability of, access to and links from Digital Twin and Employer should ensure that User has software and hardware which are capable of effectively accessing Digital Twin via an internet service provider. Employer and User are advised to use appropriate anti-virus software. We do not guarantee that Digital Twin will be free from viruses, and we are not responsible for any damage caused by viruses.

                  7.       Intellectual property
                  a.       All software provided to User in connection with Digital Twin or in the Deloitte Confidential Information or other material created by us and posted on Digital Twin is owned by us or our licensors, or other interested parties, and is protected by international intellectual property laws and conventions. Employer's and User's rights to use this software is limited to use in connection with the Purpose as set out in these terms. Employer and User may not remove any copyright, trademark or other proprietary notices of any nature from printed or electronic copies of material obtained through use of Digital Twin.
                  b.      "Deloitte Touche Tohmatsu", "Deloitte & Touche", "Deloitte", "Touche", "Tohmatsu", the Deloitte Touche Tohmatsu and the Deloitte & Touche logos (and any of the foregoing used in any combination) and product names are trademarks or registered trademarks belonging to us or to the member firms of the Deloitte Touche Tohmatsu worldwide organization (each, one of our "Affiliates"). All other product names mentioned are the trademarks of their respective owners.
                  c.       Employer and User warrant that all intellectual property rights in the Provided Information belong to Employer or User, and Employer and User hereby grant a perpetual, free and non-exclusive license to us to use the Provided Information in the context of Digital Twin.

                  8.       Limitation of liability
                  a.       The provisions set out below apply only to liability in relation to Employer's and User's use of Digital Twin.
                  b.      Use of Digital Twin by Employer and User in connection with an engagement will, in addition to the terms set out in this agreement, be subject to the terms and conditions of the engagement letter. Accordingly, the provisions set forth below will apply to an affiliate's liability arising from Employer's or User's use of Digital Twin and are in addition to any liability provisions set out in the engagement letter.
                  c.       Unless stated otherwise in the engagement letter, none of us other than the affiliate who is a party to the engagement letter with Employer will have any liability to Employer or User or any other party in connection with employer's or user's use of Digital Twin.
                  d.      Use of Digital Twin by Employer or User other than pursuant to an engagement letter will, in addition to the terms set out below, be subject to the following provision: the information available on Digital Twin is not intended to constitute professional accounting advice, including without limitation, accounting, tax, legal, consulting, investing, or general business advice. Neither Employer nor User should rely on this information in any business decision without consulting an independent professional advisor.
                  e.      If Employer is not out client, we do not accept responsibility or assume any duty of care to Employer or User for any of the information on Digital Twin, or for use thereof or reliance thereon.
                  f.        Subject to Paragraph 8g below, Digital Twin is provided on an "As Is" and "As Available" basis, and we expressly disclaim all conditions, warranties and representations expressed or implied by statute, common law or otherwise including, without limitation, warranties of merchantability, title, fitness for a particular purpose, non-infringement, compatibility, security and accuracy. Use of Digital Twin is at Employer's and User's own risk. Subject to paragraph 8g below, neither we nor any of our affiliates, or any partners, principals, managers, members, stockholders or employees of ours or theirs will be liable for any special, direct and indirect, foreseen and not foreseen, present or future, incidental, consequential (including, without limitation, loss of savings or goodwill or any additional expenses incurred or rendered nugatory), loss of profit, or punitive damages or any other damages whatsoever, whether in an action of contract, statute, tort/depict (including, without limitation, negligence) or otherwise, relating to the use of Digital Twin.
                  g.       Although we will take reasonable steps to maintain the continuity of the Digital Twin service, we accept no liability for any suspension, interruption, temporary unavailability, loss of data or fault occurring in Digital Twin's service or any of the consequences thereof. We will take reasonable steps to protect Digital Twin from unauthorized access; however, the Internet is not secure and, therefore, we accept no liability for any breach of the security of Digital Twin, whether arising as a result of our allocation of access rights or otherwise.
                  h.      Nothing in these terms shall operate to exclude or restrict our liability for death or personal injury resulting from negligence, fraud, or where such exclusion or restriction would not be permitted by law.
                  i.        Employer agrees to indemnify us and our affiliates against all costs, claims, losses, or expenses incurred by or made against us and/or our affiliates as a result of any breach by Employer or User of the terms of this agreement, including, without limitation, third-party claims that provided information infringes on the intellectual property rights of such third party.

                  9.       Termination
                  a.       If Employer or User breach any of the terms of this agreement, if our relationship with Employer terminates for any reason or if we become aware of unauthorized use of User's Access Code, or the Access Code issued to any of Employer's personnel, we may terminate this agreement immediately and deactivate or delete related user accounts. In all other cases we shall give Employer or User 14 days written notice (which may include notice given via Digital Twin) of our intention to terminate this agreement.
                  b.      On termination of this agreement for whatever reason, Employer and User shall immediately take all necessary steps to cease using Digital Twin and all Access Codes. Employer and User agree either to return to us all copies of Deloitte Confidential Information in their possession or to destroy all copies of Deloitte Confidential Information in their possession and to certify such destruction to us in writing, within 7 days of the termination of this agreement.
                  c.       Termination of this agreement shall be without prejudice to the right to enforce any right which arose prior to termination.
                  d.      Paragraphs 1, 2b, 2d, 6a, 7c and 8 and this Paragraph 9 shall remain in effect even though this agreement has been terminated.

                  10.   Security
                  a.       Digital Twin currently employs the following security features, which may be modified by us at our sole discretion and without notice to Employer or User:
                  i.            User Names and Passwords. At the highest level, basic access control restricts access to only those users with a valid member name and password. Passwords are encrypted and will initially be assigned but may be changed by the user.
                  ii.           Object-level Access Control. Authorized users can control the type of access (i.e. none, read, or write) each user has to each piece of information in a project.
                  iii.          Encryption of Transmitted Data via SSL. Data is transmitted between the server and the user's machine, using secure sockets layer (SSL).
                  iv.           Server Security. Servers reside in our secure data center, employing a variety of industry-accepted procedures and tools to safeguard the network and servers within the data center facility. Additionally, steps have been taken to provide for redundancy and system availability. In the case of a system outage, there are procedures for back up and recovery.
                  b.      Security Audit.  Periodically, we will conduct an audit review and assessment of both system and process around security.

                  11.   General
                  a.       This agreement constitutes the entire agreement of the parties with respect to the subject matter hereof (except for the Engagement Letter, if any, and in the event of any inconsistency between this agreement and the Engagement Letter, the provisions of the Engagement Letter shall prevail, but only to the extent of the conflict or inconsistency), and supersedes all prior or contemporaneous agreements, understanding and discussions with respect to such subject matter (except for such Engagement Letter, if any). Employer and User agree that they have not been induced to enter into this agreement by any pre-contractual representations and hereby waive any remedy in relation to such representation. Nothing in this Paragraph 11a excludes liability for any fraudulent pre-contractual representations.
                  b.      Employer and User may not assign this agreement without written permission from us. We may assign this agreement in our sole discretion.
                  c.       Any of our Affiliates may enforce the terms of this agreement.
                  d.      If any of the terms set out above is found to be unenforceable, the remainder of the terms shall continue to apply.
                  e.      This agreement will be governed by, and construed in accordance with, the laws of the State of New York, without giving effect to the choice-of-law principles thereof; provided, however, that if the Engagement Letter (or another written agreement between Employer and us) specifically states that this agreement will be governed by the laws of another jurisdiction instead, such laws shall govern this agreement. The parties hereby agreed to submit to the exclusive jurisdiction of the State of New York or the federal courts located in that State, provided, however, that if the Engagement Letter (or another written agreement between Employer and us) specifically provides for another venue, the terms of such other agreement shall govern.
                  f.        The headings used or contained in this agreement are for convenience and reference only and shall not affect the construction of this agreement.

                  For and on behalf of Deloitte Touche Tohmatsu and one or more of its national member firms
</p>
                </div>
              </IonModal>

              <IonRow>
                <Link to={'/ContactUs'}>
                  <IonButton id="footer-contactUs" className="footerButtons header-tab" fill="clear" href="/tab1">
                    Contact Us
              </IonButton>
                </Link>
              </IonRow>
            </IonCol>
          </IonRow>
        </div>
      </div>
    </IonGrid>
  )
}

export default DTFooter;