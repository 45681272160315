import React from "react";
import { Link } from 'react-router-dom'
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRow
} from "@ionic/react";

import './BotCard.css';

interface inputs {
  id: string;
  botName?: string;
  description?: string;
  likes?: string;
  stars: string;
  views?: string;
  rank?: string;
  platforms?: string[];
  sdlcPhase?: string[];
  subCategory?: string;
  type?: string
}

const BotCard: React.FC<inputs> = inputs => {
  var draft = inputs.description as string
  var draft1 = draft.split('.')
  var description = draft1[0];
  var platforms = inputs.platforms as string[]
  var uiPath = "" as string
  var automationAnywhere = "" as string
  var bluePrism = "" as string
  var iRPA = '' as string
  var subCategoryImage = require('../Assets/finance-card.png') as string
  var subCategory = inputs.subCategory as string

    if(platforms!==undefined)
    {
    platforms.forEach((element) => {
      if (element === "UIPath") {
        uiPath = require('../Assets/ui_Symbol_PREF_rgb_Orange_digital_129x110.png');
      }
      else if (element === "Automation Anywhere") {
        automationAnywhere = require('../Assets/automation-anywhere-logo-a-only.png');
      }
      else if (element === "Blue Prism") {
        bluePrism = require('../Assets/triangle-updated.png');
      } else if(element === 'iRPA') {
          iRPA = require('../Assets/iRPA.jpg');
      }
    });
  }



  if (subCategory === "Finance") {
    subCategoryImage = require('../Assets/finance-card.png')
  }
  else if (subCategory === "Sales") {
    subCategoryImage = require('../Assets/sales-card.png')
  }
  else if (subCategory === "PMO") {
    subCategoryImage = require('../Assets/pmo-card.png')
  }
  else if (subCategory === "Procurement") {
    subCategoryImage = require('../Assets/procurement-card.png')
  }
  else if (subCategory === "Human Resources") {
    subCategoryImage = require('../Assets/humanresources-card.png')
  }
  else if (subCategory === "Marketing") {
    subCategoryImage = require('../Assets/marketing-card.png')
  }
  else {
    subCategoryImage = require('../Assets/operationsandsupplychain-card.png')
  }


  return (
    <IonCard className="bot-card fade-in one" id={"BotDetail-" + inputs.id}>
      <Link to={`/BotDetail/${inputs.id}`} >
        <IonCardHeader className="bot-card-header">
          <IonCardTitle>{inputs.botName}</IonCardTitle>
          <img src={subCategoryImage} className="bot-card-header-sub-category" alt="sub category"></img>
        </IonCardHeader>
        <IonCardContent>
          <p className="bot-card-content">{description}
          </p>

          {/* <IonGrid>
          <IonRow align-items-start>
            <IonCol  size="2" align-self-start>
              <IonImg className="Group-4" src="/assets/group-4.svg" alt="" />

            </IonCol>
            <IonCol push="8" align-self-start>
              <IonImg className="Group-4" src="/assets/group.svg" alt="" />
            </IonCol>
          </IonRow>
          <IonRow align-items-start>
            <IonCol size="2" className="number">{inputs.likes}</IonCol>
            <IonCol size="8">
            <BotRating StarRating={inputs.stars}/>
            </IonCol>
            <IonCol size="2" className="number">{inputs.views}</IonCol>
          </IonRow>
        </IonGrid>*/}
          <IonRow>
            <div>
              <div className="platform-div">
                <img src={uiPath}></img>
              </div>
              <div className="platform-div">
                <img src={bluePrism}></img>
              </div>
              <div className="platform-div">
                <img src={automationAnywhere} className="automationAnywhere"></img>
              </div>
            <div className='platform-div'>
              <img src={iRPA} className="iRPA"></img>
            </div>
            </div>
          </IonRow>
        </IonCardContent>
      </Link>
    </IonCard>
  );
};
export default BotCard;
