import React, { useState, useEffect, useRef}  from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonButton,
  IonLabel,

  IonModal,
  IonItem,
  IonInput} from "@ionic/react";
import "./Home.css";
import {useDispatch} from 'react-redux';
import DTFooter from '../Components/footer';
import useAnalytics from '../Components/useAnalytics';
import Axios from "axios";
import { botStruct } from "./Type";
import {allbots} from "../actions/index.js";
import {apiURL} from "../Services/config"
import Customslider from "../Components/Customslider";

const apiEndPoint = apiURL;


window.name=""
const Home: React.FC = () => {
  useAnalytics(null);
  const contentRef = useRef(null);
  const [allbot,setallbots]=useState([]);
  const [,setloaded]=useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let popularBots:botStruct[];
  useEffect(()=>{
    Axios.get(apiEndPoint + 'api/getBots').then(res => setallbots(res.data))
    setloaded(true)},[])
  popularBots=allbot as unknown as botStruct[];
  // popularBots=popularBots.splice(0,4);
  const dispatch=useDispatch();
  dispatch(allbots(allbot));
  const [showModal, setShowModal] = useState(false);
  return (
    <IonPage id="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents={true} ref={contentRef}>
        <div className="content-margin sliderHeight" >
          <IonModal cssClass="submitIdea-model" isOpen={showModal}>
            <IonItem>
              <IonLabel>Default Label</IonLabel>
              <IonInput></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Floating Label</IonLabel>
              <IonInput></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">Fixed Label</IonLabel>
              <IonInput></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Stacked Label</IonLabel>
              <IonInput></IonInput>
            </IonItem>
            <IonButton
              className="button-size"
              onClick={() => setShowModal(false)}
            >
              Close Modal
            </IonButton>
          </IonModal>      
          <IonGrid>
            <IonRow>
            <Customslider></Customslider>
            </IonRow>
            {/* <IonRow>
              <IonCol>
                <div className="how-it-works-container">
                  <div className="second-subtitle">
                    HOW IT WORKS
                    <div className="popBtos-hrzLine howitworksline"></div>
                  </div>
                  <div className="mainHeading">
                    Choose the category of your choice
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <div className="firstrow" id="firstrow">
            </div>
            <div >
            <IonRow wrap-reverse="true">
            </IonRow>
            </div>
            <div className="secondaryrow">
            <IonRow>
            </IonRow>
            </div> */}
          </IonGrid>
        </div>
        {/* <div className="content-gray popular-bots-container" id="popular_bots">
          <div className="content-margin">
            <div className="popBots" id="popular-bots-id">
              POPULAR BOTS
              <div className="popBtos-hrzLine popular-line"></div>
            </div>
            <div className="mostUsedBots">
              Some of our most <br /> used bots
            </div>
            <div className="populerbot-section">
              <IonGrid className="grid-nopadding">
                <IonRow>
                  {popularBots.map(value => {
                    return (
                      <IonCol  id={value._id as unknown as string} sizeLg="3" sizeXs="12">
                        <BotCard
                          id={value._id as unknown as string}
                          botName={value.BotName}
                          description={value.TechnicalDetails}
                          likes={value.Likes}
                          stars={value.StarRating}
                          views={value.NoOfPeopleViewed}
                          //rank={value.Rank}
                        ></BotCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div> */}
        {/* <div className="content-margin">
          <div className="benefits">
            BENEFITS
            <div className="popBtos-hrzLine howitworksline"></div>
            <div className="mainHeading">Take control of <br></br>your bots</div>
          </div>
        </div> */}
        {/* <div className="content-margin benefit-icon-container">
          <IonRow>
            <IonCol sizeXs="12" sizeLg="4">
              <div>
              <div>
                <IonImg className="benefit-image" src={images[6].src}>
                  </IonImg>
                </div>
                <div >
                  <div className="benefit-description"><p>Get in touch<br /> with owners directly</p>
               </div>
                </div>  
                </div>
              </IonCol>
            <IonCol  sizeXs="12" sizeLg="4">
              <div >
                <IonImg className="benefit-image" src={images[6].src}>
                  </IonImg>
                  </div>
                  <div>
                  <div className="benefit-description"><p>Be more efficient by using<br />  already created bots</p>
               </div>
                </div> 

                  </IonCol>
            <IonCol sizeXs="12" sizeLg="4">
              <div>
                <IonImg className="benefit-image" src={images[6].src}>
                  </IonImg>
                  </div>
                  <div >
                  <div className="benefit-description"><p>Get a chance to even <br />showcase your own ideas</p>
               </div>
                </div> 
                
                </IonCol>
          </IonRow>
        </div> */}
        {/* <div className="content-margin">
          <div className="comm">
            <div className="heading-bold-white">JOIN THE COMMUNITY</div>
            <div className="main-heading-white">Become a Contributor</div>
            <div className="paragraph-white">
            Are you interested in becoming more involved with Deloitte's SAP Digital Twin community?<br></br>
            <br></br>Join the team and contribute to the firm's cutting edge RPA innovation<br></br>
            by sharing your bot ideas through the Submit Ideas button below.<br></br><br></br>
      
            </div>
            <IonButton
              size="small"
              className="button-size button-text"
              color="primary"
              fill="solid"
              routerLink="SubmitIdeaScreen"
              href="/SubmitIdeaScreen"
              id="submitIdeaScreen"
            >
              SUBMIT IDEA
            </IonButton>
          </div>
        </div>
        <div className="content-gray">
          <div className="content-margin content-padding">
            <div className="heading-bold">
              OTHER OPTIONS
              <div className="popBtos-hrzLine howitworksline"></div>
            </div>
            <div className="mainHeading">Looking for something else?</div>

            <div className="oneStop secondary-content-margin">
            The SAP Digital Twin community focuses on driving RPA innovation specifically within the firm's SAP offering space. For other RPA inquiries, you can start by visiting Deloitte's Robotic & Intelligent Automation Knowledge Exchange site.
            </div>
            <IonButton
              size="small"
              className="button-size button-text"
              color="primary"
              fill="solid"
              target="_blank"
              href="https://kx.deloitteresources.com/hot_topics/Pages/Robotics_and_Cognitive.aspx"
              id="other-options">
              CLICK HERE
            </IonButton>
          </div>
        </div> */}
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};

export default Home;
