const images = [
    { id: 1, src: './assets/bitmap_2@2x.png', title: 'foo', description: 'bar' },
    { id: 2, src: './assets/group-7@3x.png', title: 'foo', description: 'bar' },
    { id: 3, src: './assets/bitmap-copy-4@3x.png', title: 'foo', description: 'bar' },
    { id: 4, src: './assets/deloitteLogo.jpg', title: 'foo', description: 'bar' },
    { id: 5, src: './assets/image01.jpg', title: 'foo', description: 'bar' },
    { id: 6, src: './assets/bitmap-copy-2@3x.png', title: 'foo', description: 'bar' },
    { id: 7, src: './assets/group-7@2x.png', title: 'foo', description: 'bar' },
    { id: 8, src: './assets/homeBot_1.png', title: 'foo', description: 'bar' },
    { id: 9, src: './assets/homeBot_2.jpg', title: 'foo', description: 'bar' },
    { id: 10, src: './assets/homeBot_3.jpg', title: 'foo', description: 'bar' },
    { id: 11, src: './assets/homeBot_4.png', title: 'foo', description: 'bar' },
    { id: 12, src: './assets/homeBot_5.png', title: 'foo', description: 'bar' },
    { id: 13, src: './assets/homeBot_6.png', title: 'foo', description: 'bar' }
]

export default images;
