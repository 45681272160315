import React from 'react';
import { useState } from 'react'
import './CustomAccordion.css';
import { IonIcon } from '@ionic/react';
import { arrowDropup, arrowDropdown } from "ionicons/icons";

interface QA {
    question: string,
    answer: string
}
const CustomAccordion: React.FC<QA> = QA => {

    const [showAnswer, setshowAnswer] = useState(false);
    var second_div_class = showAnswer ? "show_up" : "hide";
    return (
        <div id="accord_container">
            <div id="header" onClick={() => setshowAnswer(showAnswer ? false : true)}>
                <IonIcon className="arrow" icon={showAnswer ? arrowDropup : arrowDropdown}></IonIcon>
                <p className="question">{QA.question}</p>
            </div>
            <div id="body" className={second_div_class}>
                {QA.answer}
            </div>
        </div>
    );
}

export default CustomAccordion;