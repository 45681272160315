import ENV from '../environment';

export const AD_tenantID = "36da45f1-dd2c-4d1f-af13-5abe46b99921";
export const AD_appId = "c8ea53d9-f802-476c-b64e-f7224e8095c0";

export const deploymentURL = "https://www.erppoweredbyai.com/";

export const authenticationEndpoint = `https://login.microsoftonline.com/${AD_tenantID}/`;
export const authorizeEndpoint = `${authenticationEndpoint}/oauth2/authorize?resource=${AD_appId}`;
export const logoutURL = `${authenticationEndpoint}/oauth2/logout?post_logout_redirect_uri=${deploymentURL}`;
export const GetMemberGroupEndpoint = `https://graph.microsoft.com/v1.0/me/getMemberGroups`;

export const apiURL = "https://digitaltwin-api.azurewebsites.net/";

export const SasBlobUrl ="https://dtbstorageprod.blob.core.windows.net/?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2050-06-25T15:15:22Z&st=2020-06-25T07:15:22Z&spr=https&sig=AOLsrwfE8J38ohovy5evuYinDl9hG8lHV92aRzm3Y48%3D";
export const blobUrl = "https://dtbstorageprod.blob.core.windows.net/";
export const containerName = "mediastoragecontainer";