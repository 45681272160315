/* eslint-disable no-redeclare */
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonHeader,
  IonSearchbar,
  IonButton,
  IonCheckbox,
  IonLoading
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import "./Offerings.css";
import DTFooter from "../Components/footer";
import BotCard from "../Components/BotCard";
import useAnalytics from '../Components/useAnalytics';
import "../App.css";
import "./Home.css";
import "./ExploreBots.css";
// import * as Samples from "../actions/ExploreBots.js";
import CustomPDFPrint from "../Components/CustomPDFPrint";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { botStruct } from './Type';
import { allbots, GetCatSubCat } from "../actions/index.js";
import CustomTree from "../Components/CustomTree";
import { apiURL } from "../Services/config";
import Modal from "react-bootstrap/Modal";
import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";
import { analytics } from "ionicons/icons";

window.name = ""
const ExploreBots: React.FC = () => {
  const botCollection = useSelector<any>(state => state.allbots as unknown as botStruct[]) || '';
  const dispatch = useDispatch();
  const [allbot, setallbots] = useState([])
  var TechBotsfromApi: botStruct[];
  var TechBots: botStruct[];
  TechBotsfromApi = botCollection as unknown as botStruct[];
  const [show, setShow] = useState(false);
  const [selectBot, setselectBot] = useState(false);
  const [checkedUIPath, setCheckedUIPath] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  var excelWorkbook: any;
  var exportExcelData: any;
  exportExcelData = [];
  const [showLoading, setShowLoading] = useState(false);

  if (TechBotsfromApi.length === 0) {
    // history.push('/Home')
    dispatch(allbots(allbot));
  }
  var [searchTerm, setSearchTerm] = useState('');
  const subcatdata: any = useSelector<any>(state => state.selectsubcat) || '';
  const [loaded, setloaded] = useState(false)
  if (loaded) {
  }

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (history.state && history.state !== null && history.state.state.from !== "ExploreBots") {
      axios.get(apiURL + 'api/getBots').then(res => {
        setallbots(res.data);
      });
      dispatch(allbots(allbot));
    }
    if (subcatdata === "") {
      if (TechBotsfromApi.length === 0) {
        axios.get(apiURL + 'api/getBots').then(res => {
          setallbots(res.data);
        });

      }
    }
    if (subcatdata !== "") {
      //axios.get('https://digitaltwin-dev-api.azurewebsites.net/api/getProposedContent/Tech Areas/'+subcatdata).then(res=>setsubcatdesc(res.data))
    }

    setloaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcatdata])
  useAnalytics(null);

  const contentRef = useRef(null);

  //TechBotsfromApi=subcatdata===""? TechBotsfromApi.filter((t:any)=>t.Category==="Tech Areas"):TechBotsfromApi.filter((t:any)=>t.SubCategory===subcattemp[0]);
  TechBotsfromApi = TechBotsfromApi.filter(filter => filter.BotName !== "");
  TechBots = TechBotsfromApi.filter(filter => filter.BotName !== "");
  var botcount: number = TechBotsfromApi.length;
  //description = subcatdata !== "" ? subcatdesc :
  //  "Automate your team's technical activities with the use of Deloitte pre-built bots. Leveraging these bots can increase team efficiency in the areas of Analytics, Core Modernization, Data Management & Governance, DevOps & Operate, Microservices & Integration, SAP Experience, CIO Services & Tech Architecture,Testing, Security, and more. Click the View Bots button below to browse the SAP Tech Areas bot portfolio.";
  //const [SubCategoryForFilter,GetCatSubCat]=useState([]);
  var SubCategoryForFilter: any = useSelector<any>(state => state.GetCatSubCat) || '';
  var GetTypeForCategory: any = useSelector<any>(state => state.GetTypeOfCategory) || '';
  if (SubCategoryForFilter !== "clearFilter") {
    if (SubCategoryForFilter !== "" && localStorage.getItem("searchflag") === "0") {
      var CatSubCat = SubCategoryForFilter.split('/');
      if (sessionStorage.getItem('catFilter') !== CatSubCat[1] && searchTerm !== '') {
        setSearchTerm('');
      }
      if (CatSubCat[0] === 'Platforms') {
        var _filter = TechBotsfromApi.filter((product) => product.Platforms != null);
        var TechBots = _filter.filter(child => (child.Platforms.filter(subchild => subchild === CatSubCat[1]).length > 0)
        && (child.Type === GetTypeForCategory));
        botcount = TechBots.length;
      } else if (CatSubCat[0] === 'getBotsBySDLCPhase') {
        var sdlcFilter = TechBotsfromApi.filter((response) => response.SDLCPhase != null);
        var TechBots = sdlcFilter.filter(child => (child.SDLCPhase.filter(subchild => subchild === CatSubCat[1]).length > 0) && (child.Type === GetTypeForCategory));
        botcount = TechBots.length;
      } else {
        TechBotsfromApi = (searchTerm !== '') ? TechBotsfromApi : (TechBotsfromApi.filter((t: any) => t.Category === CatSubCat[0] && t.SubCategory === CatSubCat[1] && (t.Type && (t.Type === GetTypeForCategory))));
        botcount = TechBotsfromApi.length;
      }
      sessionStorage.setItem('catFilter', CatSubCat[1]);
    }

    if (searchTerm !== "") {
      localStorage.setItem("searchflag", "1");
    }
    if (searchTerm !== "" && localStorage.getItem("searchflag") === "1") {
      TechBots = TechBotsfromApi.filter((bot: any) =>
        (bot.Category && bot.Category.toLowerCase().includes(searchTerm)) ||
        (bot.Category && bot.Category.toUpperCase().includes(searchTerm)) ||
        (bot.Category && bot.Category.includes(searchTerm)) ||
        (bot.SubCategory && bot.SubCategory.toLowerCase().includes(searchTerm)) ||
        (bot.SubCategory && bot.SubCategory.toUpperCase().includes(searchTerm)) ||
        (bot.SubCategory && bot.SubCategory.includes(searchTerm)) ||
        (bot.BotName && bot.BotName.toLowerCase().includes(searchTerm)) ||
        (bot.BotName && bot.BotName.toUpperCase().includes(searchTerm)) ||
        (bot.BotName && bot.BotName.includes(searchTerm)) ||
        (bot.Description && bot.Description.toLowerCase().includes(searchTerm)) ||
        (bot.Description && bot.Description.toUpperCase().includes(searchTerm)) ||
        (bot.Description && bot.Description.includes(searchTerm))
      );
      //if (TechBots.length == 0) {
      //{
      var _filter = TechBotsfromApi.filter((product) => product.Platforms != null);
      var TechBotsPlatform = _filter.filter(t => t.Platforms.filter(y => y.toLowerCase().includes(searchTerm.toLowerCase()) || y.toUpperCase().includes(searchTerm.toUpperCase())).length > 0);
      //}
      TechBotsPlatform.forEach((e: botStruct) => {
        if (TechBots.filter(c => c._id === e._id).length === 0) {
          TechBots.push(e);
        }
      });
      var sdlcFilter = TechBotsfromApi.filter((response) => response.SDLCPhase != null);
      var sdlcFilteredData = sdlcFilter.filter(child => child.SDLCPhase.filter(y => y.toLowerCase().includes(searchTerm.toLowerCase()) || y.toUpperCase().includes(searchTerm.toUpperCase())).length > 0);
      sdlcFilteredData.forEach((e: botStruct) => {
        if (TechBots.filter(c => c._id === e._id).length === 0) {
          TechBots.push(e);
        }
      });

      botcount = TechBots.length;
      localStorage.setItem("searchflag", "0");
    } else {
      if (SubCategoryForFilter.split('/')[0] !== 'Platforms' &&
        SubCategoryForFilter.split('/')[0] !== 'getBotsBySDLCPhase') {
        TechBots = TechBotsfromApi;
        localStorage.setItem("searchflag", "0");
      }
    }
  }
  else {
    setSearchTerm("");
    window.name = "";
    dispatch(GetCatSubCat(""));
  }
  if (TechBots.length > 0 && localStorage.getItem("HomeSliderNav") === "ProcessAreas") {
    TechBots = TechBots.filter(c => c.Category === "Process Areas");
    botcount = TechBots.length;
    localStorage.removeItem("HomeSliderNav");
  }
  else if (TechBots.length > 0 && localStorage.getItem("HomeSliderNav") === "TechAreas") {
    TechBots = TechBots.filter(c => c.Category === "Tech Areas");
    botcount = TechBots.length;
    localStorage.removeItem("HomeSliderNav");
  }
  const updateBot = (name: any) => {
    localStorage.setItem("searchflag", "1");
    setSearchTerm(name.target.value);
  }
  const getAllCheckedBots = (e: any) => {
    setShow(true)
    let arraydtl1: any = "";

    const checkboxes = document.querySelectorAll('.checkBoxAlign');
    checkboxes.forEach((checked) => {
      if (checked.getAttribute("aria-checked") === "true") {
        arraydtl1 = arraydtl1 + "~" + checked.getAttribute("value");
      }
    });

    //setCheckedUIPath(e.detail.value)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let arraydtl: any = "";

    arraydtl = e.detail.value;
    window.name = arraydtl1;
    //dispatch(GetCatSubCat(e.detail.value));
  };
  //// added for clear click
  const onSearchClearClick = (e: any) => {
    localStorage.setItem("searchflag", "0");
    setSearchTerm('');
  }

  const exportExcel = async (e: any) => {
    let botArray: any = '';
    const checkboxes = document.querySelectorAll('.checkBoxAlign');
    checkboxes.forEach((checked) => {
      if (checked.getAttribute("aria-checked") === "true") {
        botArray = botArray + "~" + checked.getAttribute("value");
      }
    });
    window.name = botArray;
    var selectedBotId: any = window.name.split('~').filter(Boolean);
    if (selectedBotId.length === 0) {
      setDisplayErrorMessage(true);
    }
    const result = await getExportExcelInfo(selectedBotId);
    if (result && result.length) {
      excelWorkbook.save();
    }
  }

  const getExportExcelInfo = async (selectedBotId: any) => {
    let exportData = [];
    exportExcelData.length = 0;
    selectedBotId = selectedBotId.filter((arrElement: string) => arrElement !== "null");
    for (let i = 0; i < selectedBotId.length; i++) {
      let getBotResponse = axios.get(apiURL + "api/getBot/" + selectedBotId[i]);
      exportData.push(getBotResponse);
    }
    const data = await Promise.all(exportData);
    data.forEach(response => {
      exportExcelData.push(response.data);
      exportExcelData.forEach((data: any) => {
        if (data.Platforms) {
          data.Platforms = data.Platforms.toString();
        }
      })
    });
    return exportExcelData;
  }

  //// fore better performance
  let searchTimeout: any = null;
  const onSearchInputChanged = (e: any) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const searchText = { ...e };
    searchTimeout = setTimeout(() => {
      updateBot(searchText);
    }, 500);

    //return () => clearTimeout(timer);
    //setSearchTerm(e.target.value);
  }

  const handleCardMouseEnter = (e: any) => {
    setselectBot(true);
  }

  const handleCardMouseLeave = (e: any) => {
    const checkboxes = document.querySelectorAll('.checkBoxAlign');
    let checked = false;

    checkboxes.forEach(el => {
      console.log()
      if (el.getAttribute("aria-checked") === "true") {
        checked = true;
      }
    })

    setselectBot(checked);
  }

  const selectAllCards = () => {
    const selectAllFlag = selectAll ? false : true;
    setselectBot(selectAll ? false : true)
    setSelectAll(selectAll ? false : true);
    console.log(selectAllFlag);
    setCheckedUIPath(selectAllFlag);
    
  }

  if (loaded)
    return (
      <IonPage className="content-gray">
        <IonHeader>
          {/* <IonToolbar className="page-subheader"> */}
          {/* <IonTitle> */}

          {/* <SubHeader list={subCategories}/> */}
          {/* </IonTitle> */}
          {/* </IonToolbar> */}
        </IonHeader>

        <IonContent scrollEvents={true} ref={contentRef}>
          <IonGrid id="tech-bots">

            <div className="content-gray">
              <div className="  content-margin">
                <IonRow>
                  <IonCol>
                    <br /><br />
                  </IonCol>
                  <IonCol>

                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size-xs="12" size-sm="12" size-lg="2.5">
                    <CustomTree ProcessAreaDisplay={true} TechAreaDisplay={true} PlatformDisplay={true} SDLCPhaseVisible={true}></CustomTree>
                    {/* <IonIcon className="arrow arrow-color" icon={funnel}></IonIcon> */}
                  </IonCol>
                  <IonCol size-xs="6" size-sm="6" size-lg="9.5">
                    <p className="bOTSTORE">BOT STORE</p>
                    <div className="row">
                      <div className="column">
                        <p id="line2Copy5"></p>
                        <p></p>
                      </div>
                      <div className="column1">
                        <IonSearchbar className="search" value={searchTerm} placeholder="Search" animated onInput={e => onSearchInputChanged(e)} onIonClear={e => onSearchClearClick(e)}  >
                        </IonSearchbar>
                      </div>
                      <div className="column select-all-icon">
                        {/* <IonButton className="BtnHeightforPrint" onClick={(e) => getAllCheckedBots(e)}>
                          Print
                        </IonButton> */}
                        <IonCheckbox
                          slot="end"
                          checked={selectAll}
                          className="selectAll"
                          onIonChange={(e) => {selectAllCards()}}
                        >
                        </IonCheckbox>
                      </div>
                      <div className="column">
                        <img src="../Assets/download_xl.svg" className="download-icon" alt="Download Excel" onClick={(e) => {exportExcel(e)}}></img>
                        <img src="../Assets/downloadpdf.svg" onClick={(e) => getAllCheckedBots(e)} className="download-icon" alt="Download PDF"></img>
                        <ExcelExport data={exportExcelData} fileName="Bot Store Inventory.xlsx" 
                          ref={exporter => {
                            excelWorkbook = exporter;
                          }}>
                          <ExcelExportColumn field="BotName" title="Bot Name"/>
                          <ExcelExportColumn field="Category" title="Category" />
                          <ExcelExportColumn field="SubCategory" title="Sub-Category" />
                          <ExcelExportColumn field="Description" title="Description"/>
                          <ExcelExportColumn field="Platforms" title="Platforms"/>
                          <ExcelExportColumn field="Tags" title="Tags"/>
                        </ExcelExport>
                      </div>
                      <div className="column">
                        <div className="bots-count">{botcount} results</div>
                      </div>
                    </div>
                    <br />
                    {TechBots.map((value, index) => {
                      return (
                        // <IonRow size-sm="6" size-lg="3">
                        <div className="changebotcardwidth"
                          onMouseEnter={(e) => handleCardMouseEnter(e)}
                          onMouseLeave={(e) => handleCardMouseLeave(e)}
                        >
                          <IonCheckbox
                            slot="end"
                            value={value._id}
                            checked={checkedUIPath}
                            //onIonChange={(e) => getAllCheckedBots(e)}
                            className={
                              selectBot
                                ? "checkBoxAlign bot-card-checkbox fade-in one"
                                : "checkBoxAlign bot-card-checkbox fade-in one displayNone"
                            }
                          />
                          <BotCard
                            key={value._id}
                            id={value._id as unknown as string}
                            description={value.TechnicalDetails}
                            botName={value.BotName}
                            likes={value.Likes}
                            views={value.NoOfPeopleViewed}
                            //rank={value.Rank}
                            // stars={value.StarRating}
                            platforms={value.hasOwnProperty('Platforms') && value.Platforms.length > 0 ? value.Platforms : []}
                            sdlcPhase={value.hasOwnProperty('SDLCPhase') && value.SDLCPhase !== null && value.Platforms.length > 0 ? value.SDLCPhase : []}
                            stars={index.toString()}
                            subCategory={value.SubCategory}
                            type={value.Type}
                          ></BotCard>
                        </div>
                        // </IonRow>
                      );
                    })}
                  </IonCol>
                </IonRow>
              </div>
            </div>
          </IonGrid>
          <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        spinner='crescent'
                    />
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modelwidth modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
            scrollable={true}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title id='ModalHeader'></Modal.Title> */}
            </Modal.Header>
            <Modal.Body >
              <CustomPDFPrint InputData={true} arraydtl={[]}>
                {" "}
              </CustomPDFPrint>
            </Modal.Body>
            <Modal.Footer>
              {/* <IonButton  onClick={handleClose}>
            Close
          </IonButton>
          <IonButton  onClick={handleClose}>
            Download
          </IonButton> */}
            </Modal.Footer>
          </Modal>

          {/*Excel button modal*/}
          <Modal
            show={displayErrorMessage}
            onHide={() => setDisplayErrorMessage(false)}
            dialogClassName="modelwidth modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
            scrollable={true}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body >
              <div className="alert">Select a bot to download!</div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
          <DTFooter />
        </IonContent>
      </IonPage >
    ); else { return (<div>Loading...</div>) }
};

export default ExploreBots;