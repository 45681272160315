import {AdalConfig, adalGetToken, AuthenticationContext} from "react-adal";
import {AD_appId, AD_tenantID, authorizeEndpoint, deploymentURL} from "./config";

const endpoint = deploymentURL;

const adalConfig: AdalConfig = {
    cacheLocation: "localStorage",
    clientId: AD_appId,
    endpoints: {
        api: AD_appId,
    },
    postLogoutRedirectUri: endpoint,
    redirectUri: endpoint,
    tenant: AD_tenantID,
};

class AdalContext {
    private authContext: AuthenticationContext;

    constructor() {
        this.authContext = new AuthenticationContext(adalConfig);
        this.authContext.handleWindowCallback();
    }

    get AuthContext() {
        return this.authContext;
    }

    public getResourceId() {
        return (adalConfig && adalConfig.endpoints) ? adalConfig.endpoints.api : "";
    }

    public GetToken(urlResource: string = authorizeEndpoint): Promise<string | null> {
        return adalGetToken(this.authContext, urlResource);
    }

    public Aquire(urlResource: string = authorizeEndpoint): void {
        console.log("Calling the aquire token just in case");
        return this.authContext.acquireToken(urlResource, (data) => {
            console.log(data, "is the callback");
        });
    }

    public updateToken() {
        return this.GetToken().then((updatedToken: string|null) => {
            localStorage.setItem("adal.token", updatedToken || "");
            return updatedToken;
        })
        .catch((error: Error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }

    public LogOut() {
        sessionStorage.clear();
        this.authContext.logOut();
    }
}

const adalContext: AdalContext = new AdalContext();
export default adalContext;
