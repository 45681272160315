import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { useHistory } from "react-router";
import "./SubHeader.css";
import { useDispatch } from 'react-redux';
import { selectsub } from '../actions/index.js';
interface subcategoryinput {
  subinput: string;
}

interface MenuItemText {
  menuName: string;
}

interface arrow {
  text: String;
  className: string;
}

interface SubHeaderProps {
  list: string[]
}

const SubHeader = ({ list }: SubHeaderProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("0");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();
  // One item component
  // selected prop will be passed
  const MenuItem = (menuName: MenuItemText) => {
    return (
      <div id={`menu-${menuName}`} color="dark" className={selected ? "sub-menu-label active-subheader" : "sub-menu-label"}>
        {menuName.menuName}
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Item = (menuName: MenuItemText) => {
    return (<div>
      {menuName.menuName}
    </div>)
  }

  // All items component
  // Important! add unique key
  const Menu = ({ list }: SubHeaderProps) =>
    list.map(el => {
      return <MenuItem menuName={el} key={el} />;
    });

  const Arrow = (arrow: arrow) => {
    return <div className={arrow.className}>{arrow.text}</div>;
  };

  const ArrowLeft = Arrow({ text: "", className: "arrow-prev" });
  const ArrowRight = Arrow({ text: "", className: "arrow-next" });

  const menu = Menu({ list });
  const onMenuItemClick = (key: string | null | number) => {
    setSelected(key === null || typeof key == "number" ? "0" : key);
    // eslint-disable-next-line no-unused-expressions
    dispatch(selectsub(key))
    //history.push('/SubCategoryPages/'+key)
  };

  return (
    <div>
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        dragging={false}
        hideArrows={true}
        hideSingleArrow={true}
        alignCenter={true}
        translate={0}
        onSelect={key => {
          onMenuItemClick(key);
        }}
      ></ScrollMenu>

    </div>
  );
};
export default SubHeader;
