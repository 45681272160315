import React from 'react'
import { IonContent, IonRow, IonCol, IonIcon, IonLabel, IonItem } from '@ionic/react'
import User from '../Components/ValidateAdmin.js';
import { contact } from 'ionicons/icons';
const MyAccount:React.FC = ()=>{
    return(
        <IonContent>
            <div className="content-margin">
            <IonRow>
                <IonCol sizeMd="2" size="big">
                <IonIcon text-center className="user-icon" icon={contact}></IonIcon>
                </IonCol>
                <IonCol sizeMd="10">
                    <IonItem>
                        <IonLabel>
                        First Name: {User.given_name}
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                        Last Name: {User.family_name}
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                        Email: {User.unique_name}
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
            </div> 
        </IonContent>
    )
}
export default MyAccount