import React, { useState } from "react";
import {
  IonPage,
  IonHeader,

  IonContent,
  IonGrid,
  IonRow,
  IonIcon,
  IonCol,
  IonLabel,

  IonTextarea,
  IonModal,
  IonButton,
  IonImg
} from "@ionic/react";
import { arrowRoundBack } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import images from "../images";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
import "./SubmitIdeaScreen.css";
import "./Inquiry.css";
import "./ContactUs.css"

const Contact = ({ history }: RouteComponentProps) => {

  const [messageInput, setMessageInput] = useState(false);

  const messageInputChange = (e: CustomEvent) => {
    if (e.detail.value.length > 1) {
      setMessageInput(true);
    } else { setMessageInput(false) }
  }

  const [showModal, setShowModal] = useState(false);
  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonContent>
        <div className="content-margin  submit-header-Inquiry header-font">
          {" "}
          <IonButton fill="clear" className="header-font" onClick={() => history.goBack()}><IonIcon
            icon={arrowRoundBack}
            className="back-icon contact-back"
          ></IonIcon>{" "}
            <p className="inner-header">Contact us</p>
          </IonButton>
        </div>
        <IonGrid>
          <div className="full-width">
            <div className="input-container body-margin label-font">
              <IonRow>
                <IonRow className="contact_container">
                  <IonCol size-xs="12" size-sm="12" size-lg="8">
                    <IonRow>
                      <div className="full-width label-font">
                        <p className="info-box">In case of any questions, queries please write to us below in no more than 600 characters.<br />
                    We'll get back to you soon</p>

                        <IonLabel className="message-header">YOUR MESSAGE/QUERY</IonLabel>
                      </div>
                      <div className="full-width ">
                        <IonTextarea
                          className="inputbox"
                          rows={10}
                          placeholder="Go ahead, we're listening..."
                          maxlength={600}

                          onIonChange={(e) => messageInputChange(e)}
                        ></IonTextarea>
                      </div>
                    </IonRow>
                    <IonModal cssClass="Inquiry submitIdea-model" isOpen={showModal} onDidDismiss={() => { setShowModal(false) }}>
                      <div className="ModalContainer">
                        {<p className="modal-bigtext">THANK YOU FOR<br />CONTACTING US</p>}
                        <p className="modal-message">
                          Our team will get back to you soon.</p>
                        <div className="ModalButtonContainer">
                          <IonButton
                            id="return-home-from-contactUs"
                            fill="solid"
                            className="button-text"
                            onClick={() => {
                              setShowModal(false);
                              history.push("/home");
                            }}

                          >
                            RETURN TO HOMEPAGE
                      </IonButton>
                        </div>
                      </div>
                    </IonModal>
                    <div className="button-container">
                      <IonButton
                        id="contact"
                        fill="solid"
                        className="bot-detail-message button-text"
                        disabled={!(messageInput)}
                        onClick={() => setShowModal(true)}
                      >
                        Submit
                  </IonButton>
                    </div>
                  </IonCol>
                  <IonCol size-xs="12" size-sm="12" size-lg="4">
                    <IonImg
                      className="contact-image"
                      src={images[6].src}
                    ></IonImg>

                  </IonCol>
                </IonRow>
              </IonRow>
            </div>
          </div>
          <div className="content-margin">
          </div>

        </IonGrid>
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};
export default Contact;
