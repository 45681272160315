import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonTextarea,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";
import "./Offerings.css";
import { arrowRoundBack } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
import "./SubmitIdeaScreen.css";
import useAnalytics from '../Components/useAnalytics';


const SubmitIdea = ({ history }: RouteComponentProps) => {
  useAnalytics(null);

  const [teamInput, setteamInput] = useState(false);
  const [messageInput, setmessageInput] = useState(false);
  const [message, setmessage] = useState('');
  const [team, setteam] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileName, setFileName] = useState('');
  const teamInputChange = (e: CustomEvent) => {
    setteam(e.detail.value)
    if (e.detail.value.length > 1) {
      setteamInput(true);
    } else { setteamInput(false) }
  }
  const messageInputChange = (e: CustomEvent) => {
    setmessage(e.detail.value)
    if (e.detail.value.length > 1) {
      setmessageInput(true);
    } else { setmessageInput(false) }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getFileName = (event: any) => {
    setFileName(event.target.files[0].name);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <div className="content-margin">
            <IonButton
              fill="clear"
              className="submitHeader"
              onClick={() => history.goBack()}
            >
              {" "}
              <IonIcon
                id="return-home-from-submit-screen"
                icon={arrowRoundBack}
                className="back-icon submit-back"
                onClick={() => history.push("/home")}
              >
                {" "}
              </IonIcon>{" "}
              <p className="header-text">SUBMIT YOUR IDEA</p>
            </IonButton>

            <div className="main-container">
              <div className="inputs-container  bottom-border">
                <IonRow>
                  <IonCol size-xs="12" size-sm="12" size-lg="12">
                    <IonRow className="inputRow">
                      <div className="full-width header">
                        <IonLabel>Contributors</IonLabel>
                      </div>
                      <div className="full-width">
                        <IonInput
                          className="inputbox contributor-input"
                          placeholder="Please mention names of all contributors"
                          onIonChange={(e) => teamInputChange(e)}
                          maxlength={100}
                          value={team}
                        ></IonInput>
                      </div>
                    </IonRow>
                    <IonRow className="inputRow">
                      <div className="full-width header">
                        <IonLabel>Your Idea/Bot Description</IonLabel>
                      </div>
                      <div className="full-width">
                        <IonTextarea
                          className="inputbox"
                          rows={10}
                          placeholder="Your brief about your idea or the description of the bot developed"
                          onIonChange={(e) => messageInputChange(e)}
                          maxlength={1500}
                          value={message}
                        ></IonTextarea>
                      </div>
                    </IonRow>
                    {/* <IonRow className="inputRow">
                      <div className="full-width header">
                        <IonLabel>Upload Media</IonLabel>
                      </div>
                      <IonLabel className="upload-description header">
                        Upload any images/Videos relating to your idea or your
                        bot media
                      </IonLabel>
                      
                      <div className="full-width">
                        <div className="uploadfile">
                          <input
                            id="imageUpload"
                            type="file"
                            className="ion-input inputbox uploadinput upload-input"
                            accept="image/png, image/jpeg"
                            onChange={e=>getFileName(e)}
                          ></input>
                          <IonIcon className="cloudUpload" icon={cloudUpload}></IonIcon>
                        </div>
                      </div>
                      <IonLabel className="upload-description header">
                        {fileName}
                      </IonLabel>
                    </IonRow> */}
                    {/* <IonModal
                      cssClass="submitIdea-model"
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        setteam('');
                        setmessage('');
                      }}
                    >
                      <div className="ModalContainer">
                        <p className="modal-bigtext">
                          Thank you
                          <br /> for your response!
                        </p>
                        <p className="modal-message"> 
                        The Idea Submission website functionality is currently unavailable. Please email the SAP Bot Council directly with your idea submission:<br/>
                        kamurali@deloitte.com<br/>
                        sharshberger@deloitte.com<br/>
                        thoshing@deloitte.com<br/>

                        </p>
                        <div className="ModalButtonContainer">
                          <IonButton
                            id="return-home-from-submit-idea-success"
                            fill="solid"
                            onClick={() => {
                              setShowModal(false);
                              history.push("/home");
                            }}
                            className="button-text"
                          >
                            RETURN TO HOMEPAGE
                          </IonButton>
                          <IonButton
                            id="submit-another-idea"
                            fill="outline"
                            color="primary"
                            className="button-font button-text home-submit"
                            onClick={() => {
                              setShowModal(false);
                              setteam('');
                              setmessage('');
                            }}
                          >
                            SUBMIT ANOTHER IDEA
                          </IonButton>
                        </div>
                      </div>
                    </IonModal> */}
                    <div>
                      <IonButton
                        id="submit-idea"
                        fill="solid"
                        // onClick={() => setShowModal(true)}
                        className="button-text"
                        disabled={!(teamInput && messageInput)}
                        onClick={() => {
                          window.location.href = `mailto:dftebotstore@deloitte.com?subject=Idea for Bot Store&body=Names: ${team}. Your Idea/Bot Description: ${message}`;
                        }}
                      >
                        SUBMIT
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </div>
          </div>
        </IonGrid>
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};
export default SubmitIdea;
