import React, { useEffect } from "react";
import { useState } from "react";

import "./CustomPDFPrint.css";
import {
  IonLabel,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
} from "@ionic/react";
import { PDFExport } from "@progress/kendo-react-pdf";
import axios from "axios";
import { apiURL } from "../Services/config";
import { botStruct } from "../pages/Type";
interface CategoryDisplay {
    InputData: boolean,
    arraydtl:any[];
}
const CustomPDFPrint: React.FC<CategoryDisplay> = (CategoryDisplay) => {
  let pdfExportComponent: any = "";
  let downloadButtonPath = require('../Assets/download-button.png') as string;
  const exportPDFWithComponent = () => {
    pdfExportComponent.save();
  };
  const windw = window.name;
  const botId =windw// "5e85df8064f0d540601ff000~5e85df8064f0d540601ff029"; //match.params.id;

  const [botJson, setbotJson] = useState("");
  
  const [, setCarouselAttachments] = useState([]);
  let BotDetail: botStruct;
  const imageArray = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF'];
  const videoArray = ['.mp4', '.MP4', '.ogg', '.OGG'];
  //let arraydtl: any[] = [];

  BotDetail = (botJson as unknown) as botStruct;
 
  useEffect(() => {
    const carouselResponse: any = [];
   
    botId.split('~').map((BId:any) => {

    axios.get(apiURL + "api/getBot/" + BId).then((res) => {
      let response = res.data;
      const imageArray = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF'];
        const videoArray = ['.mp4', '.MP4', '.ogg', '.OGG'];
      if (response.Attachments && response.Attachments.length) {
        response.Attachments.map((element: string | string[]) => {
    
          imageArray.map(fileFormat => {
            if (element.includes(fileFormat)) {
              const carouselItem = {
                Id: BId,
                src: element,
                type: 'image'
              }
              carouselResponse.push(carouselItem)
            }
            return console.log(fileFormat);
          });

          videoArray.map(fileFormat => {
            if (element.includes(fileFormat)) {
              const carouselItem = {
                Id: BId,
                src: element,
                type: 'video'
              }
              // const ImageItems = {
              //   Id: BId,
              //   Image: element
              // }
              //KeyValColl.push(ImageItems);
              carouselResponse.push(carouselItem)
            }
            return console.log(fileFormat);
          });
          return console.log(element);
        });
      }
      if (carouselResponse.length > 0) {
        //setKeyValPair(KeyValColl);
        setCarouselAttachments(carouselResponse);
      } else {
        const staticImages: any = [
          {
            Id:BId,
            src:
              "https://images.pexels.com/photos/669622/pexels-photo-669622.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            type: "image",
          },
          {
            Id:BId,
            src:
              "https://images.pexels.com/photos/2058147/pexels-photo-2058147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            type: "image",
          },
          {
            Id:BId,
            src:
              "https://images.pexels.com/photos/669616/pexels-photo-669616.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            type: "image",
          },
          {
            Id:BId,
            src:
              "https://images.pexels.com/photos/590044/pexels-photo-590044.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            type: "image",
          },
          {
            Id:BId,
            src:
              "https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            type: "image",
          },
        ];
       
        setCarouselAttachments(staticImages);
      }
      CategoryDisplay.arraydtl.push(response);
      setbotJson(response);
    });
    return console.log(BId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);
  // console.log(botJson);
  //var catId: string;
  //var subcatId: string;

  let tagList: string[];
  BotDetail.Tags === undefined
    ? (tagList = [""])
    : (tagList = BotDetail.Tags.split(";"));

  //catId = BotDetail.Category;
  //subcatId = BotDetail.SubCategory;


  /* onThumbnail */


if(windw!=="")
{
  return (
    <div>
      <img onClick={() => exportPDFWithComponent()} src={downloadButtonPath} alt="" className="alignLeft"></img>

     {/* <img src={downloadButtonPath} className="download-button-img d-print-none" onClick={() => {
                        window.print();
                      }}></img> */}
                 
      {/* // <IonButton onClick={() => exportPDFWithComponent()} className="alignLeft">
      //   Download
      // </IonButton> */}
        <PDFExport
          ref={(component) => (pdfExportComponent = component)}
          margin={90}
          
          fileName={`Bot Details - ${new Date().getFullYear()}`}
        >
            
          {/* Content start*/}
                {/* Back Button */}

                {CategoryDisplay.arraydtl.map((BotDetail:any) => {
                      return (
             
                <div >
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                      <IonCardHeader>
                        <IonCardTitle className="text-center">
                          <div className="text-uppercase bot-name">
                            {BotDetail.BotName}
                          </div>
                          <div className="small-hrzLine"></div>
                        </IonCardTitle>
                      </IonCardHeader>
                    </div>

                    {/* <div className="col-6">
                      Primary Image Container
                      <img
                        ref={primaryImageRef}
                        // src=""
                        className="rounded-lg"
                        alt="Primary Image"
                        style={{
                          display: showImageContainerState ? "block" : "none",
                        }}
                      />

                      Primary Video Container
                      <video
                        controls
                        width="100%"
                        ref={primaryVideoRef}
                        className="rounded-lg"
                        // src=""
                        style={{
                          display: !showImageContainerState ? "block" : "none",
                        }}
                      ></video>
                    </div> */}

                    {/* Technical Details */}
                    <div className="col-12">
                      <div className="pb-5">
                        Published: {BotDetail.PublishedDate}
                      </div>
                      <div className="pb-5">{BotDetail.TechnicalDetails}</div>
                      {/* Thumbnails */}
                      <div className="row">
                        {/* Backward Arrow */}
                        {/* <span className="arrowIconWrap">
                          <img
                            src={arrowBackSvg}
                            alt="Backward Arrow"
                            className="arrowBackSvgWrapper"
                          />
                        </span> */}
                        <div
                          className="row"
                        >
                          <div className="col-12">
                          {BotDetail.Attachments !== undefined && BotDetail.Attachments.map(
                            (element: any, index: number) => {
                              if (imageArray.some(a =>a.includes(element.substring(element.lastIndexOf('.'))))) {
                              
                                return (
                                  <img
                                    key={index}
                                    src={element}
                                    alt="thumbnail"
                                    className="img-thumbnail w-25 m-2"
                                  />
                                );
                              } else if (videoArray.some(a =>a.includes(element.substring(element.lastIndexOf('.'))))) {
                               
                                return (
                                  <img
                                    key={index}
                                    src={element}
                                    alt="thumbnail"
                                    className="img-thumbnail w-25 m-2"
                                  />
                                );
                              }
                            }
                          )}
                          </div>
                        </div>
                        {/* Forward Arrow  */}
                        {/* <span className="arrowIconWrap">
                          <img
                            src={arrowForwardSvg}
                            alt="Forward Arrow"
                            className="arrowForwardSvgWrapper"
                          />
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="description-divider"></div>
                  {/* Description */}
                  <div className="row">
                    <div className="col-12 pb-2">
                      <span className="font-weight-bold">DESCRIPTION</span>
                      <p className="mt-2 pre-line">{BotDetail.Description}</p>
                    </div>
                  </div>
                  {/* Contributors */}
                  <div className="row">
                    <div className="col-12 pb-2">
                      Contributors: {BotDetail.Contributers}
                    </div>
                  </div>
                  {/* Tags */}
                  <div className="row">
                    <div className="col-12 pb-2">
                      Tags:
                      <IonCol className="benefits">
                        {tagList === undefined
                          ? ""
                          : Object.values(tagList).map((value, index) => {
                              return (
                                <IonChip key={index}>
                                  <IonLabel>{value}</IonLabel>
                                </IonChip>
                              );
                            })}
                      </IonCol>
                    </div>
                  </div>
                </div>
                
                );
            })
    }       
          {/* Content End */}
        </PDFExport>
      
    </div>
  );
}
else
{
  return <div className="alert">
  Select a bot to preview!
</div>;
}
};

export default CustomPDFPrint;