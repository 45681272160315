import * as jwt_decode from 'jwt-decode';
import UserModel from '../Models/UserModel'
var User = UserModel
const token = localStorage.getItem('adal.access.token.keyc8ea53d9-f802-476c-b64e-f7224e8095c0');
if (token) {
	const decoded = jwt_decode(token)
	if (decoded.unique_name === 'echedraui@deloitte.com' || decoded.unique_name === 'sharshberger@deloitte.com' || decoded.unique_name === 'stharavattath@deloitte.com'
             || decoded.unique_name === 'sidvallabhaneni@deloitte.com' || decoded.unique_name === 'saarvind@deloitte.com' || decoded.unique_name === 'sbondre@deloitte.com' || decoded.unique_name === 'amisheth@deloitte.com' || decoded.unique_name === 'biuppal@deloitte.com' || decoded.unique_name === 'shenze@deloitte.com') {
		UserModel.isAdmin = true
		Object.assign(UserModel, decoded)
	}
}

export default User
