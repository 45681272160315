export const search=(key )=>{
    return {
        type:'SEARCH',
        payload:window.name
    }
}
export const selectsub=(key)=>{
    return{
        type:'selectsubcat',
        payload : key 
    }
   
}
export const allbots=(allbots)=>{
    return{
        type:'fetchallbots',
        payload:allbots
    }
}
export const GetCatSubCat=(key )=>{
    return {
        type:'GetCatSubCat',
        payload:window.name
    }
}

export const GetTypeOfCategory = (type) => {
    return {
        type:'GetTypeOfCategory',
        payload: type
    }
}