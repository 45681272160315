import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import BotModel from '../Models/BotModel'
import Axios from 'axios'
import isAdmin from '../Components/ValidateAdmin.js';
import { IonList, IonItem, IonLabel, IonContent, IonButton, IonToast, IonCheckbox, IonCard, IonCardContent, IonLoading, IonRadioGroup, IonRadio } from '@ionic/react';
import { apiURL, blobUrl, containerName, SasBlobUrl } from "../Services/config"
import * as FileStorage from '../Components/FileStorage'
import { BlobServiceClient } from "@azure/storage-blob";
let videoThumbnail = "" as string;
videoThumbnail = require("../Assets/video-thumbnail.png");
let fileArray: any;

const apiEndPoint = apiURL;
const ViewBot: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match, location }) => {
    const [bot, setBot] = useState({} as BotModel)
    const [showToast, setShowToast] = useState(false);
    const [showToast1, setShowToast1] = useState(false);
    const [showToast2, setShowToast2] = useState(false);
    const [showToast3, setShowToast3] = useState(false);
    const [checkedUIPath, setCheckedUIPath] = useState(false);
    const [checkedAutomationAnywhere, setCheckedAutomationAnywhere] = useState(false);
    const [checkedBluePrism, setCheckedBluePrism] = useState(false);
    const [checkediRPA, setCheckediRPA] = useState(false);
    const [isConfigurationChecked, setCheckedConfiguration] = useState(false);
    const [isDesignChecked, setCheckedDesign] = useState(false);
    const [isBuildChecked, setCheckedBuild] = useState(false);
    const [isTestChecked, setCheckedTest] = useState(false);
    const [isCutoverChecked, setCheckedCutover] = useState(false);
    const [isBusinessProcessChecked, setCheckedBusinessProcess] = useState(false);
    const botId = match.params.id;
    const [carouselAttachments, setCarouselAttachments] = useState([]);
    const [showLoading, setShowLoading] = useState(true);
    const blobSasUrl = SasBlobUrl;
    const [selected, setSelected] = useState<string>('');

    // Create a new BlobServiceClient
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    useEffect(() => {
        apiCall();
        setShowLoading(false);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = () => {
        Axios.get(apiEndPoint + `api/getBot/${botId}`).then((res: any) => {
            const response = res.data;
            // For Carousel
            const imageArray = ['.jpg', '.jpeg', '.png', '.gif'];
            const videoArray = ['.mp4', '.ogg', '.MP4'];
            const carouselResponse: any = [];
            if (response.Attachments && response.Attachments.length) {
                response.Attachments.map((element: string | string[]) => {

                    imageArray.map(fileFormat => {
                        if (element.includes(fileFormat)) {
                            const carouselItem = {
                                src: element,
                                type: 'image'
                            }
                            carouselResponse.push(carouselItem)
                        }
                        return console.log(fileFormat);
                    });

                    videoArray.map(fileFormat => {
                        if (element.includes(fileFormat)) {
                            const carouselItem = {
                                src: element,
                                type: 'video'
                            }
                            carouselResponse.push(carouselItem)
                        }
                        return console.log(fileFormat);
                    });
                    return console.log(element);
                });
            }
            if (carouselResponse.length >= 0) {
                setCarouselAttachments(carouselResponse);
            }
            setBot(response);
            loadPlatformData(response);
            autoPopulateSDLCFields(response);
            loadType(response);
        });

    };
    const updateBot = () => {
        setShowLoading(true);
        uploadFiles(fileArray);
        setPlatformData();
        setSDLCData();
        setType();
        Axios.post(apiEndPoint + `api/updateBot/${botId}`, bot).then(res => {
            setShowToast(true);
            apiCall();
            setShowLoading(false);
        })
    }

    const updateBotAttachments = () => {
        // setPlatformData();
        setShowLoading(true);
        Axios.post(apiEndPoint + `api/updateBotAttachments/${botId}`, bot).then(res => {

            // setShowToast(true);
            apiCall();
            setShowLoading(false);

            // setShowToast2(true);
        })
    }

    const setFiles = async (fileInput: any) => {
        fileArray = [];
        fileArray = fileInput;
    }

    const uploadFiles = async (fileInput: any) => {
        try {
            //FileStorage.createContainer(botId.toString());
            let containerClient = blobServiceClient.getContainerClient(containerName);
            const promises = [];
            if (bot.Attachments === undefined) {
                bot.Attachments = [];
            }

            for (const file of fileInput.files) {
                const blockBlobClient = containerClient.getBlockBlobClient(file.name);
                promises.push(blockBlobClient.uploadBrowserData(file));

                if (bot.Attachments.indexOf(blobUrl + containerName + "/" + file.name) < 0) {
                    bot.Attachments.push(blobUrl + containerName + "/" + file.name);
                }
            }
            await Promise.all(promises);

            // listFiles();
            updateBotAttachments();
            setShowToast2(true);
            console.log('Successfully uploaded all fiels.');
            // FileStorage.downloadFile();
            // window.location.reload();
            fileArray = [];
        }
        catch (error) {
            console.log(error.message);
        }
    }

    const removeBot = () => {
        Axios.delete(apiEndPoint + `api/removeBot/${botId}`).then(res => {
            setShowToast1(true);
            apiCall();
            setTimeout(() => {
                history.push('/')
            }, 2000);

        })
    }

    const setPlatformData = () => {
        bot.Platforms = [];
        if (checkedUIPath) {
            bot.Platforms.push("UIPath");
        }
        if (checkedAutomationAnywhere) {
            bot.Platforms.push("Automation Anywhere");
        }
        if (checkedBluePrism) {
            bot.Platforms.push("Blue Prism");
        }
        if (checkediRPA) {
            bot.Platforms.push('iRPA');
        }
    }
    const setType = () => {
        bot.Type = '';
        if (selected === 'Commercial') {
            bot.Type = 'Commercial';
        }
        if (selected === 'GPS') {
            bot.Type = 'GPS'
        }
    }
    const setSDLCData = () => {
        bot.SDLCPhase = [];
        if (isConfigurationChecked) {
            bot.SDLCPhase.push('Configuration');
        }
        if (isDesignChecked) {
            bot.SDLCPhase.push('Design');
        }
        if (isBuildChecked) {
            bot.SDLCPhase.push('Build');
        }
        if (isTestChecked) {
            bot.SDLCPhase.push('Test');
        }
        if (isCutoverChecked) {
            bot.SDLCPhase.push('Cutover');
        }
        if (isBusinessProcessChecked) {
            bot.SDLCPhase.push('Business Process');
        }
    }
    const loadPlatformData = (data: BotModel) => {
        console.log(data.Platforms);
        if (data.Platforms !== undefined) {
            data.Platforms.forEach((element) => {
                if (element === "UIPath") {
                    setCheckedUIPath(true);
                }
                else if (element === "Automation Anywhere") {
                    setCheckedAutomationAnywhere(true);
                }
                else if (element === "Blue Prism") {
                    setCheckedBluePrism(true);
                } else if (element === 'iRPA') {
                    setCheckediRPA(true);
                }
            });
        }
    }
    const loadType = (data: BotModel) => {
        if (data.Type !== '') {
            setSelected(data.Type);
        }
    }
    const autoPopulateSDLCFields = (data: BotModel) => {
        if (data.SDLCPhase !== undefined && data.SDLCPhase !== null) {
            data.SDLCPhase.forEach(element => {
                if (element === 'Configuration') {
                    setCheckedConfiguration(true);
                } else if (element === 'Design') {
                    setCheckedDesign(true);
                } else if (element === 'Build') {
                    setCheckedBuild(true);
                } else if (element === 'Test') {
                    setCheckedTest(true);
                } else if (element === 'Cutover') {
                    setCheckedCutover(true);
                } else if (element === 'Business Process') {
                    setCheckedBusinessProcess(true);
                }
            });
        }
    }
    const onDeleteHandle = (source: string, type: string, event: any) => {
        FileStorage.deleteFiles(source.split('/')[3], source.split('/')[4]);
        //listFiles();
        apiCall();
        setShowLoading(false);
        console.log(source);
        console.log(type);
        // FileStorage.deleteFiles(source.split('/')[3],source.split('/')[4]);
        FileStorage.deleteFiles(containerName, source.split('/')[4]);
        if (bot.Attachments.indexOf(blobUrl + containerName + "/" + source.split('/')[4]) > -1) {
            const index = bot.Attachments.indexOf(blobUrl + containerName + "/" + source.split('/')[4], 0);
            bot.Attachments.splice(index, 1);
            updateBotAttachments();
        }
        setShowToast3(true);
        // window.location.reload();
    };
    if (isAdmin) {
        return (
            <IonContent>
                <div className="content-margin">
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message="Bot Details have been updated successfully."
                        duration={2000}
                    />
                    <IonToast
                        isOpen={showToast1}
                        onDidDismiss={() => setShowToast1(false)}
                        message="Bot has been deleted successfully."
                        duration={2000}
                    />

                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={3000}
                        spinner='crescent'
                    />

                    <IonToast
                        isOpen={showToast2}
                        onDidDismiss={() => setShowToast2(false)}
                        message="Files has been uploaded successfully."
                        duration={2000}
                    />
                    <IonToast
                        isOpen={showToast3}
                        onDidDismiss={() => setShowToast3(false)}
                        message="File has been deleted successfully."
                        duration={2000}
                    />


                    <IonList>
                        <IonItem>
                            <IonLabel>
                                Category:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea
                                    value={bot.Category}
                                    onChange={e => setBot({ ...bot, Category: e.target.value as any })}
                                />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Sub Category:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.SubCategory}
                                    onChange={e => setBot({ ...bot, SubCategory: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Contributers:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Contributers}
                                    onChange={e => setBot({ ...bot, Contributers: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Bot Name:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.BotName}
                                    onChange={e => setBot({ ...bot, BotName: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Technical Details:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.TechnicalDetails}
                                    onChange={e => setBot({ ...bot, TechnicalDetails: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Tags:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Tags}
                                    onChange={e => setBot({ ...bot, Tags: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                System Prerequisites:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.SystemPrerequisites}
                                    onChange={e => setBot({ ...bot, SystemPrerequisites: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Description:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.Description}
                                    onChange={e => setBot({ ...bot, Description: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Proposed Content:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <textarea value={bot.ProposedContent}
                                    onChange={e => setBot({ ...bot, ProposedContent: e.target.value as any })} />
                            </IonLabel>
                        </IonItem>
                        <IonItem>

                            <IonLabel>

                                Past Projects:

</IonLabel>

                            <IonLabel class='fixedLabel'>

                                <textarea value={bot.PastProjects}

                                    onChange={e => setBot({ ...bot, PastProjects: e.target.value as any })} />

                            </IonLabel>

                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Platforms:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                {/* {checkboxList.map(({ val, isChecked }, i) => (
                        <IonItem key={i}>
                        <IonLabel>{val}</IonLabel>
                        <IonCheckbox slot="end" value={val} checked={isChecked}
                        // onChange={e=>setBot({ ...bot, ProposedContent:e.target.value as any})} />
                        onIonChange={e => setChecked(e.detail.checked)} />
                        </IonItem>
                    ))} */}

                                <IonItem key="1">
                                    <IonLabel>UIPath</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedUIPath}
                                        onIonChange={e => setCheckedUIPath(e.detail.checked)} />
                                </IonItem>

                                <IonItem key="2">
                                    <IonLabel>Automation Anywhere</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedAutomationAnywhere}
                                        onIonChange={e => setCheckedAutomationAnywhere(e.detail.checked)} />
                                </IonItem>

                                <IonItem key="3">
                                    <IonLabel>Blue Prism</IonLabel>
                                    <IonCheckbox slot="end" value="UIPath" checked={checkedBluePrism}
                                        onIonChange={e => setCheckedBluePrism(e.detail.checked)} />
                                </IonItem>
                                <IonItem key="4">
                                    <IonLabel>iRPA</IonLabel>
                                    <IonCheckbox slot="end" value="iRPA" checked={checkediRPA}
                                        onIonChange={e => setCheckediRPA(e.detail.checked)} />
                                </IonItem>
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>SDLC Phase</IonLabel>
                            <IonLabel class='fixedLabel'>
                                <IonItem key="5">
                                    <IonLabel>Configuration</IonLabel>
                                    <IonCheckbox slot="end" value="Configuration" checked={isConfigurationChecked}
                                        onIonChange={e => setCheckedConfiguration(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="6">
                                    <IonLabel>Design</IonLabel>
                                    <IonCheckbox slot="end" value="Design" checked={isDesignChecked}
                                        onIonChange={e => setCheckedDesign(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="7">
                                    <IonLabel>Build</IonLabel>
                                    <IonCheckbox slot="end" value="Build" checked={isBuildChecked}
                                        onIonChange={e => setCheckedBuild(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="8">
                                    <IonLabel>Test</IonLabel>
                                    <IonCheckbox slot="end" value="Test" checked={isTestChecked}
                                        onIonChange={e => setCheckedTest(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="9">
                                    <IonLabel>Cutover</IonLabel>
                                    <IonCheckbox slot="end" value="Cutover" checked={isCutoverChecked}
                                        onIonChange={e => setCheckedCutover(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                                <IonItem key="10">
                                    <IonLabel>Business Process</IonLabel>
                                    <IonCheckbox slot="end" value="BusinessProcess" checked={isBusinessProcessChecked}
                                        onIonChange={e => setCheckedBusinessProcess(e.detail.checked)}></IonCheckbox>
                                </IonItem>
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Media:
                    </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <input type='file' id='file' className='input-file' onChange={e => setFiles(e.target)} multiple />
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <div className="col-4">
                                <IonLabel>
                                    Uploaded Media:
                    </IonLabel>
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    {carouselAttachments.map((element: any, index: number) => {
                                        if (element.type === 'image') {
                                            return (<IonCard className="col-3">
                                                <IonCardContent>
                                                    <img
                                                        key={index}
                                                        src={element.src}
                                                        alt="thumbnail"
                                                        className="img-thumbnail"
                                                    />
                                                    <IonButton color="secondary"
                                                        onClick={(event) => onDeleteHandle(element.src, element.type, event)}
                                                    >Delete</IonButton>
                                                </IonCardContent>
                                            </IonCard>)
                                        } else if (element.type === 'video') {
                                            return (<IonCard className="col-3">
                                                <IonCardContent>
                                                    <img
                                                        key={index}
                                                        src={videoThumbnail}
                                                        alt="thumbnail"
                                                        className="img-thumbnail"
                                                    />
                                                    <IonButton color="secondary"
                                                        onClick={(event) => onDeleteHandle(element.src, element.type, event)}
                                                    >Delete</IonButton>
                                                </IonCardContent>
                                            </IonCard>)
                                        }
                                    })}
                                </div>
                            </div>
                        </IonItem>
                        <IonItem>
                            <IonLabel>
                                Type:
                            </IonLabel>
                            <IonLabel class='fixedLabel'>
                                <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}>
                                    <IonItem>
                                        <IonLabel>Commercial</IonLabel>
                                        <IonRadio slot="start" value="Commercial"/>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>GPS</IonLabel>
                                        <IonRadio slot="start" value="GPS"/>
                                    </IonItem>
                                </IonRadioGroup>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                    <IonButton color="primary" onClick={() => updateBot()}>Update Bot</IonButton>
                    <IonButton color="danger" onClick={() => removeBot()}>Remove Bot</IonButton>
                    {/* <IonButton color="success" onClick={()=>refresh()}>Refresh</IonButton> */}
                </div>
            </IonContent>
        )
    } else {
        return (
            <IonContent>
                <div className="content-margin">
                    <h2>Error message: You are not authorized to access this page</h2>
                </div>
            </IonContent>
        )
    }
}
export default ViewBot
