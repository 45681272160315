import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,

  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,

} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./Offerings.css";
import { arrowRoundBack } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import DTFooter from "../Components/footer";
import "../App.css";
import "./Home.css";
import "./FAQ.css";
import "./SubmitIdeaScreen.css";
import useAnalytics from '../Components/useAnalytics';
import CustomAccordion from '../Components/CustomAccordion'
import Axios from "axios";
import { apiURL } from "../Services/config"

const apiEndPoint = apiURL;
const FAQ = ({ history }: RouteComponentProps) => {
  const [QA, setQA] = useState('');
  var FAQ: any;
  useEffect(() => {
    Axios.get(apiEndPoint + 'api/getFAQs').then(res => setQA(res.data))
    //console.log(TechBotsfromApi+"HI Hello Welcome");

  }, [])
  useAnalytics(null);
  let count = 1;
  FAQ = Object.values(QA);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <div className="content-margin">
            <IonButton
              fill="clear"
              className="submitHeader"
              onClick={() => history.goBack()}
            >
              {" "}
              <IonIcon
                id="return-home-from-submit-screen"
                icon={arrowRoundBack}
                className="back-icon back-FAQ"
                onClick={() => history.push("/home")}
              >
                {" "}
              </IonIcon>{" "}
              <p className="header-text">Frequently asked questions</p>
            </IonButton>

            <div className="main-container">
              <div className="inputs-container  bottom-border">
                <IonRow className="qa_box">

                  {FAQ.map((value: any) => {
                    return (
                      <CustomAccordion question={"Q" + count++ + ")   " + value.question} answer={value.answer}></CustomAccordion>
                    );
                  })
                  }
                </IonRow>
              </div>
            </div>
          </div>
        </IonGrid>
        <DTFooter />
      </IonContent>
    </IonPage>
  );
};
export default FAQ;
