import search from './search.js';

import {combineReducers} from 'redux';
import selectsubcat from './selectSubCat.js';
import allbots from './allbots.js';
import GetCatSubCat from './GetCatSubCat.js';
import GetTypeOfCategory from './GetTypeOfCategory.js';
const allReducers=combineReducers({
    search: search, 
    selectsubcat : selectsubcat,
    allbots: allbots,
    GetCatSubCat: GetCatSubCat,
    GetTypeOfCategory: GetTypeOfCategory
})

export default allReducers;